<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/hcbbg1.png" alt="" class="img-responsive" width="100%">
            <div class="container bgS box boxM">
                <p class="p1 font1">慧测评</p>
                <p class="p2 font2">慧测评是学多多推出的智能化测试平台，适用于小学阶段式测评、期末测评、招生测评等移动端智慧测评。该产品结合等第制评价，以游戏闯关式、游园式、情景式等多种形式，通过移动端设备实现在线测评、在线成绩展示。</p>
                <p class="p2 font2">无纸化的测评过程、多维度的测评内容、多样化的闯关形式、实时化的评价结果，有的放矢地改进教师的教和学生的学。</p>
            </div>
        </div>
       <div class="hcbbg">
           <div class="container-fluid">
               <div class="container">
                   <img src="../../assets/img/hcbbt1.png" alt="" class="img-responsive hcbTitle" width="35%">
                   <div class="row hcpmt100">
                       <div class="col-sm-4 col-sm-offset-2 hcpDiv">
                            <span class="hcpspan">
                                整个测评基于移动终端。学生只需带好电子身份证，用平板扫描二维码登录，便可以参与测评，操作方便快捷。
                            </span>
                       </div>
                       <div class="col-sm-6">
                           <img src="../../assets/img/hcpimg1.png" alt="" class="img-responsive">
                       </div>
                   </div>
               </div>
           </div>
           <div class="container-fluid hcbbg1">
               <div class="container">
                   <img src="../../assets/img/hcpbt2.png" alt="" class="img-responsive hcbTitle" width="35%">
                   <div class="row hcpmt100">
                       <div class="col-sm-6 col-sm-offset-1">
                           <img src="../../assets/img/hcpimg2.png" alt="" class="img-responsive">
                       </div>
                       <div class="col-sm-4 hcpDiv">
                            <span class="hcpspan">
                                符合学生认知发展规律，将学科素养融入游戏，对学生进行能力测评，在测评过程中，学生的学习认知、逻辑思维、语言表达、审美情趣、科学素养、身体素质以及创造力、想象力等得以展现。
                            </span>
                       </div>

                   </div>
               </div>
           </div>
           <div class="container-fluid hcbbg1">
               <div class="container">
                   <img src="../../assets/img/hcpbt3.png" alt="" class="img-responsive hcbTitle" width="35%">
                   <div class="row hcpmt100">
                       <div class="col-sm-4 hcpDiv col-sm-offset-2" >
                            <span class="hcpspan">
                                新颖的页面设计、多元的题目风格、便捷的应用操作，学生带着新奇的体验，在一点、一触、一滑中即可完成测评。闯关形式互动性强，符合小朋友爱玩、爱尝试的天性，使综合素质评价能更好地服务于孩子们的成长。
                            </span>
                       </div>
                       <div class="col-sm-5 col-sm-offset-1">
                           <img src="../../assets/img/hcpimg31.png" alt="" class="img-responsive">
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-sm-5 col-sm-offset-2">
                           <img src="../../assets/img/hcpimg32.png" alt="" class="img-responsive">
                       </div>
                       <div class="col-sm-5 hcpmt100 hcpnmt" >
                           <img src="../../assets/img/hcpimg33.png" alt="" class="img-responsive">
                       </div>
                   </div>
               </div>
           </div>
           <div class="container-fluid hcbbg1">
               <div class="container">
                   <img src="../../assets/img/hcpbt4.png" alt="" class="img-responsive hcbTitle" width="35%">
                   <div class="row hcpmt100">
                       <div class="col-sm-4 hcpDiv col-sm-offset-2" >
                           <div style="margin-top: -100px">
                               <span class="hcpspan">
                               评价结果实时呈现，并记录于后台，教师可以实时在线查看学生测评情况，分学科、分知识点对学生、班级、年级等多维度的测评结果进行对比分析，便于老师动态掌握学生的学业水平。
                               </span>
                           </div>
                           <div style="margin-top: 82px">
                               <img src="../../assets/img/hcpimg4l.png" alt="" class="img-responsive">
                           </div>
                       </div>
                       <div class="col-sm-5 col-sm-offset-1">
                           <img src="../../assets/img/hcpimg4r.png" alt="" class="img-responsive">
                       </div>
                   </div>
               </div>
           </div>
           <div class="container-fluid hcbbg1">
               <div class="container" style="margin-bottom: 50px">
                   <img src="../../assets/img/hcpbt5.png" alt="" class="img-responsive hcbTitle" width="35%">
                   <div class="row hcpmt100">
                       <div class="col-sm-6 col-sm-offset-1">
                           <img src="../../assets/img/hcpimg5.png" alt="" class="img-responsive">
                       </div>
                       <div class="col-sm-4 hcpDiv col-sm-offset-1">
                            <span class="hcpspan">
                                上海市已有多所学校在期中期末及日常测试中采用了这种趣味测试模式，其中包括浦东新区第二中心小学、洋泾实验小学、中原路小学、平凉路第四小学、新世界实验小学、华师大一附中实验小学、南桥小学、奉贤区教育学院附属实验小学、联建小学等。
                            </span>
                       </div>

                   </div>
               </div>
           </div>
       </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../../components/header.vue'
    import Foot from '../../components/footer.vue'
    import FootBot from '../../components/footerBot.vue'

    export default {
        metaInfo: {
            title: '学多多官网—慧测评',
            meta: [
                {
                    name: 'keywords',
                    content: '快乐闯关；智慧测评；游园闯关；期末测评；基于课标测评；游戏化测评；期中测评；等第制评价；情景式测评；'
                }
            ]
        },
        name: "Hcp",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>

<style scoped>
    .hcbbg {
        background: url("../../assets/img/hcbbg.png") no-repeat;
        margin-top: -6rem;
    }
    .hcbbg1 {
        margin-top: 60px;
    }


</style>