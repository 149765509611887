<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img :src=imgList[0].imgUrl alt="" class="img-responsive" width="100%">
        </div>
        <div class="modal" id="mymodal-data" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">×</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h4 class="modal-title" style="font-weight: 500">{{title}}</h4>
                    </div>

                    <div class="modal-body ql-editor" v-html="message2">

                    </div>

                </div>
            </div>
        </div>
        <div style="background-color: #F8F9FD;padding: 20px 0">
            <div class="container weBg1">
                <div class="row">
                    <div class="col-sm-2 col-sm-offset-1">
                        <ul class="list-inline bg2ul">
                            <li v-for="(li,index) in mokuai" :key="index">
                                <div :class="{'btn':true,'weBtn': true,'m10': true,'weBtnActive': isActive == index + 1}" @click="clickcj(li.catalogCode)">{{li.catalogName}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-8 wePadding" style="background-color: #fff;min-height: 500px">
                       <div v-if="isActive == 1">
                           <h3 class="weH3">公司介绍</h3>

                               <div class="ql-editor" v-html="message">

                               </div>

                       </div>
                        <div v-if="isActive == 2">
                            <h3 style="font-weight: 600" class="weH3">公司荣誉</h3>
                            <img :src=mokuai[1].childs[0].contents[0].imgUrl alt="" class="img-responsive" style="margin-top: 20px">
                            <div class="row" style="margin-top: 20px;margin-bottom: 20px">
                                <div class="col-sm-4" v-for="(list,index) in mokuai[1].childs[1].contents" :key="index" style="margin-top: 10px">
                                    <img :src=list.imgUrl alt="" class="img-responsive" width="100%">
                                </div>
                            </div>
                        </div>
                        <div v-if="isActive == 3">
                            <h3 class="weH3" v-if="mokuai[2].childs[0].contents.length > 0">{{mokuai[2].childs[0].catalogName}}</h3>
                            <div class="row" style="margin-top: 20px;padding: 0 40px;margin-bottom: 70px">
                                <div class="col-sm-5ths" v-for="(list,index) in mokuai[2].childs[0].contents" :key="index" style="margin-top: 40px;">
                                    <img :src="list.imgUrl" alt="" class="img-responsive" width="100%">
                                </div>
                            </div>
                            <h3 class="weH3" v-if="mokuai[2].childs[1].contents.length > 0">{{mokuai[2].childs[1].catalogName}}</h3>
                            <div class="row" style="margin-top: 20px;padding: 0 40px;margin-bottom: 70px" v-if="mokuai[2].childs[1].contents.length > 0">
                                <div class="col-sm-5ths" v-for="(list,index) in mokuai[2].childs[1].contents" :key="index" style="margin-top: 40px;">
                                    <img :src="list.imgUrl" alt="" class="img-responsive" width="100%">
                                </div>
                            </div>
                        </div>
                        <div v-if="isActive == 4">
                            <h3 class="weH3">联系我们</h3>
                            <p class="weP2" style="margin-top: 28px">上海学多多教育科技有限公司</p>
                            <p class="weP2">Shanghai Xueduoduo Educational Science & Technology Co.Ltd.</p>
                            <div class="row" style="margin-top: 20px;padding: 0 10px;margin-bottom: 70px">
                                <div class="col-sm-8" style="margin-top: 20px">
                                     <div v-for="(list, index) in iconList" :key="index" class="row">
                                         <div  class="col-sm-1" style="margin-top: 10px">
                                             <img :src=list.icon alt="" width="20px">
                                         </div>

                                         <span class="weSpan col-sm-11">{{list.content}}</span>
                                     </div>
                                </div>
                                <div class="col-sm-3">
                                    <img src="../assets/img/weerweima.png" alt="" class="img-responsive" style="margin-top: 100px">
                                </div>
                            </div>
                        </div>
                        <div v-if="isActive == 5">
                            <h3 class="weH3">加入我们</h3>
                            <div class="ql-editor" v-html="message3">

                            </div>
                           <div>
                               <div class="weDiv2">
                                   <span>
                                       职位列表
                                   </span>
                               </div>
                               <table class="table">
                                    <thead class="wethead">
                                       <tr>
                                           <th style="font-weight: 400;">
                                               职位
                                           </th>
                                           <th style="font-weight: 400;">
                                               工作地点
                                           </th>
                                           <th style="font-weight: 400;">
                                               操作
                                           </th>
                                       </tr>
                                    </thead>
                                   <tbody>
                                        <tr v-for="(li,index) in mokuai[4].childs[1].contents" :key="index">
                                            <td>{{li.title}}</td>
                                            <td>{{li.description}}</td>
                                            <td><a href="" class="wea" data-toggle="modal" data-target="#mymodal-data" data-whatever="@mdo" @click="clickText(li.code,li.title)">了解详情</a></td>
                                        </tr>
                                   </tbody>
                               </table>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../components/header.vue'
    import Foot from '../components/footer.vue'
    import FootBot from '../components/footerBot.vue'
    import wexin from '../assets/img/wexinicon.png'
    import phone from '../assets/img/wephone.png'
    import dizhi from '../assets/img/wedizhi.png'
    import qq from '../assets/img/weqq.png'
    import xing from '../assets/img/wexing.png'
    import {getCarousel,listAllOnlineContentByTopParentCode,getContentByContentCode} from '../serve/index.js'
    import $ from 'jquery'

    export default {
        metaInfo: {
            title: '学多多官网—关于我们',
            meta: [
                {
                    name: 'keywords',
                    content: '学多多公司简介；学多多公司荣誉；学多多合作伙伴；学多多企业文化；'
                }
            ]
        },
        name: "we",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {
                isActive: 1,
                iconList: [
                    {icon: wexin,content: '公众号: ixueduoduo'},
                    {icon: qq,content: '官方客服QQ: 2316281680'},
                    {icon: wexin,content: '官方客服微信：xueduoduo2'},
                    {icon: phone,content: '服务热线: 400-821-1618'},
                    {icon: xing,content: '邮箱: service@xueduoduo.com'},
                    {icon: dizhi,content: '上海漕河泾开发区浦江高科技园陈行公路2388号浦江科技广场7幢801A室'},
                ],
                imgList: [{imgUrl: ''}],
                topCatalogCode: 'guanyuwomen',
                mokuai: [{catalogName: ''},{catalogName: ''}],
                contents: [{title: '',description:''}],
                code: '',
                message: '',
                message2: '',
                message3: '',
                title: ''
            }
        },
        methods: {
            clickText(code,title) {
                getContentByContentCode({code: code}).then(res => {
                    if (res.resultCode == 200) {
                        this.message2 = res.data
                        this.title = title
                    }
                })
            },
            getContentByContentCode(code) {
                getContentByContentCode({code: code}).then(res => {
                    if (res.resultCode == 200) {
                        this.message3 = res.data
                    }
                })
            },
            clickcj(v) {
                if (v == 'gongsijianjie') {
                    this.isActive = 1
                }else if (v == 'gongsirongyu') {
                    this.isActive = 2
                }else if (v == 'hezuohuoban') {
                    this.isActive = 3
                }else if (v == 'lianxiwomen') {
                    this.isActive = 4
                }else if (v == 'jiaruwomen') {
                    this.isActive = 5
                }
            },
            getCarousel() {
                getCarousel({catalogCode: this.topCatalogCode}).then(res => {
                    if (res.resultCode == 200) {
                        if (res.data.records.length > 0) {
                            this.imgList = res.data.records
                        }
                    }
                })
            },
            listAllOnlineContentByTopParentCode() {
                listAllOnlineContentByTopParentCode({topCatalogCode: this.topCatalogCode}).then(res => {
                    if (res.resultCode == 200) {
                        this.mokuai = res.data.childs
                        this.contents = res.data.contents
                        this.code = this.mokuai[0].contents[0].code
                        let self = this
                        getContentByContentCode({code: this.code}).then(res => {
                            if (res.resultCode == 200) {
                                self.message = res.data
                            }
                        })
                        this.getContentByContentCode(this.mokuai[4].childs[0].contents[0].code)
                    }
                })
            },
        },
        mounted() {
            this.getCarousel()
            this.listAllOnlineContentByTopParentCode()
        }
    }
</script>

<style scoped>
    .modal.in .modal-dialog {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
</style>