<!--
 * @Author: vince
 * @Date: 2021-02-09 10:30:35
 * @LastEditors: vince 273187189@qq.com
 * @LastEditTime: 2023-06-27 10:42:39
 * @Description: 
-->
<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/hyx_b.png" alt="" class="img-responsive" width="100%">
            <div class="container bgS box boxM" style="margin-top: 80px;">
                <p class="p1 font1" style="color: #2BB8FF;">慧研修</p>
                <p class="p2 font2">
                    在教师发展领域，学多多研发了慧研修平台。慧研修是集在线教研活动、培训、项目课题研究、教学设计、学术文章、示范辐射、带教任务、专业规划、教师专业发展报告、积分管理为一体的智慧教师专业发展平台。慧研修平台简化管理流程，提升成效，助力教师发展。
                    慧研修分标准版、轻量版两个版本。标准版能全流程记录教师的研修活动，而轻量版则侧重于记录教师的研修成果。</p>

            </div>
        </div>
        <div class="container-fluid" style="text-align: center;margin-top: -6rem">
            <div class="container" style="margin-bottom: 1rem">
                <h2>数字化互动式网络教研平台</h2>
                <div style="margin-left: 12px">
                    <span class="hengxian"></span>
                </div>
                <img src="../../assets/img/jyx1.png" alt="" class="img-responsive"
                     style="margin-top: 50px;margin-left: auto;margin-right: auto" width="40%">
            </div>
        </div>
        <div class="container-fluid jyxbg">
            <div class="container" style="margin-bottom: 1rem">
                <h2>研修活动全流程智能化系统</h2>
                <div style="margin-left: 12px">
                    <span class="hengxian"></span>
                </div>
                <div class="row" style="margin-top:50px">                   
                    <div class="col-sm-6">
                        <img src="../../assets/img/hyx_1.png" alt="" class="img-responsive">
                    </div>
                    <div class="col-sm-6">
                        <img width="70%" style="margin: 70px auto" src="../../assets/img/hyx_2.png" alt="" class="img-responsive">
                    </div>
                </div>
            </div>
        </div>        
        <div class="container-fluid jyxbg">
            <div class="container" style="margin-bottom: 1rem">
                <img class="img-responsive" src="../../assets/img/hyx_3.png" alt=""  >
            </div>
        </div>      

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
import Head from '../../components/header.vue'
import Foot from '../../components/footer.vue'
import FootBot from '../../components/footerBot.vue'

export default {
    metaInfo: {
        title: '学多多官网—慧研修',
        meta: [
            {
                name: 'keywords',
                content: '教师研修；教师培训；学科教研；教师专业发展；教师成长；'
            },
        ]
    },
    name: "Jyx",
    components: {
        Head, Foot, FootBot
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>
.jyxbg {
    margin-top: 54px;
    text-align: center;
}
</style>