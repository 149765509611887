<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/hbbg1.png" alt="" class="img-responsive" width="100%" @click="clickImg1">
            <div class="container bgS box boxM" style="margin-top: 50px">
                <p class="p1 font1">绘本森林</p>
                <p class="p2 font2">
                    绘本森林是学多多开发的一款针对3-11岁儿童的移动交互式绘本阅读平台，旨在帮助小朋友提高阅读素养，帮助老师探索阅读评价，同时鼓励家长陪伴孩子阅读，参与测评，激发小朋友阅读兴趣，培养小朋友阅读习惯。</p>
                <p class="p2 font2">经典的绘本故事，美妙的配乐音频，给孩子最好的绘本阅读体验。</p>
            </div>
        </div>
        <div class="hbbg" style="margin-top: -8rem">
            <div class="container-fluid hbbg2">
                <div class="container" style="margin-bottom: 30px">
                    <h2>交互式立体阅读</h2>
                    <div style="margin-left: 12px;margin-bottom: 2rem">
                        <span class="hengxian ycolor"></span>
                    </div>
                    <div style="text-align: left">
                        <img src="../../assets/img/liubian1.png" width="32" style="display: inline">
                        <span class="hbp">中英双语经典有声绘本故事，画面精美，科学分类，助力学生阅读！</span>
                    </div>
                    <img src="../../assets/img/hbjh1.png" alt="" class="img-responsive"
                         style="margin-top: 30px;margin-left: auto;margin-right: auto" width="70%">
                    <div style="text-align: left;margin-top: 50px">
                        <img src="../../assets/img/liubian2.png" width="32" style="display: inline">
                        <span class="hbp">读、听、演、画、编、评、配音…… </span>
                        <p class="hbp" style="margin-left: 40px"> 全方位多角度感知绘本读物，激发阅读兴趣，提升阅读能力。</p>
                    </div>

                    <div class="row" style="margin-top: 50px">
                        <div class="col-sm-1">
                        </div>
                        <div class="col-sm-5" style="margin-left: 10px">
                            <img :src="src" alt="" class="img-responsive">
                        </div>

                        <div class="col-sm-5" style="text-align: left;">
                            <div class="row imgleft cursor" @click="clickImg(1)">
                                <div class="col-sm-1">
                                </div>
                                <img src="../../assets/img/hbicon1.png" alt="" width="100%"
                                     class="img-responsive col-sm-5 imgbox-shadow imgbox imgleft" id="img1">
                                <div class="col-sm-6" style="margin-left: 20px;margin-top: 10px;">
                                    <span class="hbydspan1">绘本配音</span><br>
                                    <span class="hbydspan2" v-if="isActive1 == 1">原声提示、丰富词汇、增强口头表达能力。</span>
                                </div>
                            </div>
                            <div class="row imgleft cursor" style="margin-top: 60px" @click="clickImg(2)">
                                <div class="col-sm-1">
                                </div>
                                <img src="../../assets/img/hbicon2.png" alt=""
                                     class="img-responsive col-sm-5  imgbox imgleft" id="img2">
                                <div class="col-sm-6" style="margin-left: 20px;margin-top: 10px;">
                                    <span class="hbydspan1">绘本画一画</span><br>
                                    <span class="hbydspan2" v-if="isActive1 == 2">绘本启发、即可创作、发挥创造力。</span>
                                </div>
                            </div>
                            <div class="row imgleft cursor" style="margin-top: 60px" @click="clickImg(3)">
                                <div class="col-sm-1">
                                </div>
                                <img src="../../assets/img/hbicon3.png" alt=""
                                     class="img-responsive col-sm-5 imgbox imgleft" id="img3">
                                <div class="col-sm-6" style="margin-left: 20px;margin-top: 10px;">
                                    <span class="hbydspan1">语音测评</span><br>
                                    <span class="hbydspan2" v-if="isActive1 == 3">中英文语音测评，智能纠正学生发音。</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-1">

                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid hbbg3">
                <div class="container" style="margin-bottom: 30px">
                    <h2>多维度阅读报告</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian ycolor"></span>
                    </div>
                    <p>学校、班级、学生，多维度呈现阅读报告，让阅读有迹可循。</p>
                    <img src="../../assets/img/hbyd.png" alt="" class="img-responsive"
                         style="margin-top: 50px;margin-left: auto;margin-right: auto" width="90%">
                </div>
            </div>

            <div class="container-fluid hbbg4">
                <div class="container" style="margin-bottom: 30px">
                    <h2>教学支持</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian ycolor"></span>
                    </div>
                    <p>教师端绘本推送，一键管理学生阅读。</p>
                    <img src="../../assets/img/hbjx.png" alt="" class="img-responsive"
                         style="margin-top: 50px;margin-left: auto;margin-right: auto" width="70%">
                </div>
            </div>

            <div class="container-fluid hbbg5">
                <div class="container" style="margin-bottom: 30px">
                    <h2>更多功能</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian ycolor"></span>
                    </div>
                    <div class="row mtop60">
                        <div class="col-sm-5 hbrem">
                            <img src="../../assets/img/liubian1.png" width="32" style="display: inline">
                            <span class="hbp" style="font-weight: 600">阅读书单</span>
                            <p class="hbp hbn" style="margin-top: 10px">教师精选书单推荐，</p>
                            <p class="hbp hbn">让学生阅读更高效。</p>
                        </div>
                        <div class="col-sm-5 col-sm-offset-1">
                            <img src="../../assets/img/hbgd1.png" class="img-responsive">
                        </div>
                    </div>
                    <div class="row mtop60">
                        <div class="col-sm-5">
                            <img src="../../assets/img/hbgd2.png" class="img-responsive">
                        </div>
                        <div class="col-sm-5 col-sm-offset-1 hbrem">
                            <img src="../../assets/img/liubian2.png" width="32" style="display: inline">
                            <span class="hbp" style="font-weight: 600">绘本圈</span>
                            <p class="hbp hbn" style="margin-top: 10px">绘本推荐、阅读技巧、作品展示</p>
                            <p class="hbp hbn"> …… </p>
                            <p class="hbp hbn">畅聊绘本阅读。</p>
                        </div>

                    </div>
                    <div class="row mtop60">
                        <div class="col-sm-3  col-sm-offset-4 hbrem">
                            <img src="../../assets/img/liubian3.png" width="32" style="display: inline">
                            <span class="hbp" style="font-weight: 600">数字画像</span>
                            <p class="hbp hbn" style="margin-top: 10px">每学期生成学生阅读行为数字画像，阅读能力逐渐提升。 </p>
                        </div>
                        <div class="col-sm-5">
                            <img src="../../assets/img/hbgd3.png" class="img-responsive">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../../components/header.vue'
    import Foot from '../../components/footer.vue'
    import FootBot from '../../components/footerBot.vue'
    import src1 from '../../assets/img/hbjh2.png'
    import src2 from '../../assets/img/hbjh22.png'
    import src3 from '../../assets/img/hbjh23.png'
    import {IsPC} from '../../lib/until.js'
    import $ from 'jquery'


    export default {
        metaInfo: {
            title: '学多多官网—绘本森林',
            meta: [
                {
                    name: 'keywords',
                    content: '绘本森林;绘本森林APP；森林绘本;上海学多多教育科技有限公司出品;学多多教育;儿童读物;中英绘本；儿童故事；幼儿阅读；图画书；有声读物；科普绘本；音乐绘本；声音动听；绘本阅读；亲子阅读；育儿；绘本故事；经典绘本故事；'
                }
            ]
        },
        name: "HuiBen",
        components: {
            Head, Foot, FootBot
        },
        mounted() {
            this.ispc = IsPC()
        },
        data() {
            return {
                isActive1: 1,
                src: src1,
                ispc: false
            }
        },
        methods: {
            clickImg1() {
                if (!this.ispc) {
                    window.location.href = 'http://www.xueduoduo.com/xdd-forest.html'
                }
            },
            clickImg(v) {
                if (v == 1) {
                    this.isActive1 = 1;
                    $('#img1').addClass('imgbox-shadow')
                    $('#img2').removeClass('imgbox-shadow')
                    $('#img3').removeClass('imgbox-shadow')
                    this.src = src1
                } else if (v == 2) {
                    this.isActive1 = 2;
                    $('#img1').removeClass('imgbox-shadow')
                    $('#img2').addClass('imgbox-shadow')
                    $('#img3').removeClass('imgbox-shadow')
                    this.src = src2
                } else if (v == 3) {
                    this.isActive1 = 3;
                    $('#img1').removeClass('imgbox-shadow')
                    $('#img2').removeClass('imgbox-shadow')
                    $('#img3').addClass('imgbox-shadow')
                    this.src = src3
                }
            },
        }
    }
</script>

<style scoped>
    .hbbg {
        background: url("../../assets/img/hbbg2.png") no-repeat 0 0,
        url("../../assets/img/hbbg3.png") no-repeat 0 30%,
        url("../../assets/img/hbbg5.png") no-repeat 0 90%;
        -webkit-background-size: contain;
        background-size: contain;
    }

    .hbbg2 {
        text-align: center;
    }

    .hbbg3 {
        text-align: center;
        margin-top: 40px;
    }

    .hbbg4 {
        text-align: center;
        margin-top: 40px;
    }

    .hbbg5 {
        text-align: center;
        margin-top: 40px;
    }


</style>