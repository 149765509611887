<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../assets/img/ndbg1.png" alt="" class="img-responsive" width="100%" v-if="!imgList[0].imgUrl">
            <img :src=imgList[0].imgUrl alt="" class="img-responsive" width="100%">
        </div>
        <div style="background-color: #F8F9FD;padding: 20px 0;">
            <div class="container ndBg1">
                <h4 style="font-weight: 600;">媒体报道</h4>
                <div class="row cursor ndDiv" style="" v-for="(list,index) in mokuai" :key="index" @click="clickHyperLink(list.hyperLink,list.code)">
                    <div class="col-sm-3" style="margin: 10px 0">
                        <img :src=list.imgUrl alt="" class="img-responsive" width="100%">
                    </div>
                    <div class="col-sm-8" style="position: static">
                        <h4 class="ndH4">{{list.title}}</h4>
                        <p class="ndP">{{list.description}}</p>
                        <div class="ndSpan">
                            <span>{{list.showTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="position: relative;margin-bottom: 100px">
                <ul class="pagination" style="position: absolute;left: 50%;transform: translate(-50%, 0);">
                    <li class="cursor" @click="clickPage(pageNum - 1)">
                        <a aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li v-for="(li,index) of total" :key="index" @click="clickPage(li)" :class="{'cursor': true,'active': li == pageNum}"><a>{{li}}</a></li>
                    <li class="cursor" @click="clickPage(pageNum + 1)">
                        <a  aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../components/header.vue'
    import Foot from '../components/footer.vue'
    import FootBot from '../components/footerBot.vue'
    import {getCarousel,listContentInfoWithoutContent} from '../serve/index.js'
    import {clickContent1} from '../lib/until.js'

    export default {
        metaInfo: {
            title: '学多多官网—最新动态',
            meta: [
                {
                    name: 'keywords',
                    content: '学多多企业动态；学多多最新发展；'
                }
            ]
        },
        name: "newDynamic",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {
                imgList: [{imgUrl: ''}],
                topCatalogCode: 'zuixindongtai',
                mokuai: [{catalogName: ''},{catalogName: ''}],
                pageSize: 10,
                pageNum: this.$route.query.pageNum,
                total: 1
            }
        },
        methods: {
            clickPage(pageNum) {
                if (pageNum < 1) {

                }else if (pageNum > this.total) {

                }else {
                    this.$router.push({name: 'newDynamic',query: {pageNum: pageNum}})
                    this.$route.query.pageNum = pageNum
                    this.pageNum = pageNum
                    this.listContentInfoWithoutContent()
                }

            },
            listContentInfoWithoutContent() {
                listContentInfoWithoutContent({parentCatalogCode: this.topCatalogCode,pageSize: this.pageSize,pageNum: this.pageNum,orderByShowTime: 1}).then(res => {
                    if (res.resultCode == 200) {
                        this.mokuai = res.data.records
                        this.total = res.data.pages
                    }
                })
            },
            clickHyperLink(hyperLink,code) {
                if (hyperLink) {
                    window.location.href = hyperLink
                } else {
                    this.$router.push({name: 'reportDetail',params: {code: code,queryNum: this.pageNum}})
                }
                clickContent1(code)
            },
            getCarousel() {
                getCarousel({catalogCode: this.topCatalogCode}).then(res => {
                    if (res.resultCode == 200) {
                        if (res.data.records.length > 0) {
                            this.imgList = res.data.records
                        }
                    }
                })
            },

        },
        mounted() {
            this.getCarousel()
            this.listContentInfoWithoutContent()
        },
    }
</script>

<style scoped>

</style>