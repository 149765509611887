<!--
 * @Author: vince
 * @Date: 2023-06-26 14:17:12
 * @LastEditors: vince 273187189@qq.com
 * @LastEditTime: 2023-06-27 17:18:11
 * @Description: 
-->
<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/xlwy_b.png" alt="" class="img-responsive" width="100%" @click="clickImg1">
            <div class="container bgS box boxM" style="margin-top: 50px;bottom:115px;">
                <p class="p1 font1" style="color: #DAA20B;">心理无忧</p>
                <p class="p2 font2">
                    心理无忧是师生心理发展测评系统。一方面通过量表，从认知、个性、状态、生活等方面对师生的心理发展进行测评。另一方面，使用国际一流的脑机接口技术，通过脑电波对师生的睡眠质量、心流指数、焦虑情绪、抑郁情绪等方面进行测评。
                </p>
            </div>
        </div>
        <div class="container-fluid xlwy" style="margin-top:-58px">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="item-name">
                            <div class="item-title"><img src="../../assets/img/xlwy_icon2.png" class="xlwy_icon" alt="">
                                心理无忧量表评测</div>
                            <ul class="item-ul">
                                <li>提供丰富的心理品质测量工具和匠心编创优质内容</li>
                                <li>能获得便捷有效的心理健康百科资源</li>
                                <li>提供多维视角，增进自我和他人认识，以及为优化自己的心理品质提供保证</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        <div class="swiper xlwy-swiper">
                            <!-- Additional required wrapper -->
                            <div class="swiper-wrapper">
                                <!-- Slides -->
                                <div class="swiper-slide ">
                                    <img style="width:100%" :src="src1" alt="">
                                </div>
                                <div class="swiper-slide ">
                                    <img style="width:100%" :src="src2" alt="">
                                </div>
                                <div class="swiper-slide ">
                                    <img style="width:100%" :src="src3" alt="">
                                </div>
                            </div>
                            <!-- If we need pagination -->
                            <div class="swiper-pagination " style="bottom: 60px;"></div>

                            <!-- If we need navigation buttons -->
                            <div class="swiper-button-prev prev"></div>
                            <div class="swiper-button-next next"></div>

                            <!-- If we need scrollbar -->
                            <!-- <div class="swiper-scrollbar"></div> -->
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                        <img style="width:100%" src="../../assets/img/xlwy_pad.png" alt="">
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="item-name" style="margin-top: 60px;">
                            <div class="item-title"><img src="../../assets/img/xlwy_icon1.png" class="xlwy_icon" alt="">
                                心理无忧-慧熵心理监测系统</div>
                            <ul class="item-ul">
                                <li>佩戴“脑环”后监测三分钟，即可识别出代表心理健康状态的10大类核心指标</li>
                                <li>后台生成相关心理健康报告及提出相关干预建议</li>
                                <li>适合多场景和人群</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div style="padding:52px 124px 103px;background: #FFFFFF;margin-bottom: 100px;
border-radius: 15px;filter: drop-shadow(2px 4px 36px rgba(127,127,127,.2));">
                    <div class="item-title" style="text-align: center;margin: 0 auto 67px;">主要内容</div>
                    <img style="width:100%" src="../../assets/img/xlwy_c.png" alt="">
                </div>


            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';
import Head from '../../components/header.vue'
import Foot from '../../components/footer.vue'
import FootBot from '../../components/footerBot.vue'
import { IsPC } from '../../lib/until.js'


import src1 from '../../assets/img/xlwy_1.png'
import src2 from '../../assets/img/xlwy_2.png'
import src3 from '../../assets/img/xlwy_3.png'

export default {
    metaInfo: {
        title: '学多多官网—心理无忧',
        meta: [
            {
                name: 'keywords',
                content: ''
            }
        ]
    },
    name: "xlwy",
    components: {
        Head, Foot, FootBot
    },
    mounted() {
        this.ispc = IsPC()
        const swiper = new Swiper('.swiper', {
            // Optional parameters
            // direction: 'vertical',
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            autoHeight: true,
            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // And if we need scrollbar
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        });
    },
    data() {
        return {
            isActive1: 1,
            ispc: false,
            src1,
            src2,
            src3
        }
    },
    methods: {
        clickImg1() {
            if (!this.ispc) {
                // window.location.href = ''
            }
        },

    }
}
</script>

<style lang="scss" >
.xlwy {
   
    background-size: 250px, 300px;
    background-repeat: no-repeat;
    background-image: url(../../assets/img/xlwy_bg1.png), url(../../assets/img/xlwy_bg2.png);
    background-position-x: -99px, calc(100% + 137px);
    background-position-y: 0, 30%;

    .xlwy-swiper {
        height: auto;
    }

    .swiper-pagination-bullet-active {
        background-color: #D4D4D4;
        width: 16px !important;
        border-radius: 4px !important;
    }

    .prev,
    .next {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.06);
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 18px;
        color: #999;
        font-weight: 900;
    }

    .item-name {}

    .item-title {
        margin: 44px 0;
        font-size: 24px;
        font-weight: 400;
        color: #323C5A;
    }

    .item-ul {
        font-size: 16px;
        font-weight: 500;
        color: #8591B0;
        line-height: 2;
        padding-left: 20px;

        li {
            &::marker {
                font-size: 10px;
            }
        }

    }

    .xlwy_icon {
        width: 50px;
        height: 38px;
        margin-right: 12px;
    }
}
</style>