<template>
        <header class="navbar navbar-static-top bs-docs-nav head navbar-fixed-top boxshadow static" id="head">
            <div class="container" style="margin-top: 10px;margin-bottom: 10px">
                <div class="navbar-header">
                    <button class="navbar-toggle" type="button" data-toggle="collapse" data-target="#bs-navbar" aria-controls="bs-navbar" aria-expanded="true">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <div class="navbar-brand"  style="width: 76px;padding: 0;margin-left: 10px">
                        <img src="../assets/img/logo.png" alt=""  class="img-responsive">
                    </div>
                </div>
                <nav class="collapse navbar-collapse" aria-expanded="true" id="bs-navbar">
                    <ul class="nav navbar-nav navul">
                        <li>
                            <a :class="{'heada': true,'headActive': this.$store.state.detail == 'Main'}" @click="clickRoute('Main')">首页</a>
                        </li>
                        <li @click="menuShow = !menuShow">
                            <a :class="{'heada': true,'headActive': this.$store.state.detail == 'zhinengyingyong'}">智能应用 <span class="caret font"></span></a>
                            <div class="container headp"   v-if="menuShow&&!ispc"     style="background-color: #fff;width: 100%;z-index: 999;">
                                <div class="row">
                                    <div :class="{'col-sm-5ths': menuList.length > 4, 'col-sm-3': menuList.length < 5}" v-for="(li,index) in menuList" :key="index">
                                        <dl style="margin-left: 25%; translate(-50%, 0)">
                                            <dt>{{li.name}}</dt>
                                            <dd style="display:flex;"  @click="clickHref(l.hyperLink,l.code)" v-for="(l,index) in li.contents" :key="index">
                                                <div class="inlineBlock">
                                                    <img :src=l.imgUrl alt="" width="36" class="img-responsive">
                                                </div>
                                                <div style="margin-left: 10px;" class="inlineBlock">
                                                    <span class="menuTitle">{{l.title}}</span><br>
                                                    <span class="menuContent">{{l.description}}</span>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li  @click="clickRoute('subjectResources')">
                            <a :class="{'heada': true,'headActive': this.$store.state.detail == 'subjectResources'}">课程资源</a>
                        </li>
                        <li @click="clickRoute('pcPlatform')">
                            <a :class="{'heada': true,'headActive': this.$store.state.detail == 'pcPlatform'}">信息化平台</a>
                        </li>
                        <!-- <li @click="clickRoute('wisdomClass')">
                            <a :class="{'heada': true,'headActive': this.$store.state.detail == 'wisdomClass'}">慧园课堂</a>
                        </li> -->
                        <li @click="clickRoute('newDynamic')">
                            <a :class="{'heada': true,'headActive': this.$store.state.detail == 'newDynamic' || this.$store.state.detail == 'reportDetail'}">最新动态</a>
                        </li>
                        <li @click="clickRoute('we')">
                            <a :class="{'heada': true,'headActive': this.$store.state.detail == 'we'}">关于我们</a>
                        </li>
                    </ul>
                    <!-- <ul class="nav navbar-nav navbar-right">
                        <li>
                            <a class="btn btn-sm headBtn" :href="btnUrl[0].content">{{btnUrl[0].name}}</a>
                        </li>
                        <li>
                            <a class="btn btn-sm headBtn stu" :href="btnUrl[1].content">{{btnUrl[1].name}}</a>
                        </li>
                    </ul> -->
                </nav>
            </div>
            <div class="container-fluid" v-if="ispc">
                <div class="container headp" v-if="menuShow"  @mouseover="mouseover2" @mouseleave="setTimeLeave2"     style="position: absolute;left: 50%;transform: translate(-50%, 0);background-color: #fff;width: 100%;z-index: 999;">
                    <div class="row">
                        <div :class="{'col-sm-5ths': menuList.length > 4, 'col-sm-3': menuList.length < 5}" v-for="(li,index) in menuList" :key="index">
                            <dl class="mleft">
                                <dt>{{li.name}}</dt>
                                <dd  @click="clickHref(l.hyperLink,l.code)" v-for="(l,index) in li.contents" :key="index" style="display: flex;">
                                    <div class="inlineBlock" style="width:36px">
                                        <img :src=l.imgUrl alt="" style="width:36px;height:36px" class="img-responsive">
                                    </div>
                                    <div style="margin-left: 10px;flex:1" class="inlineBlock">
                                        <span class="menuTitle">{{l.title}}</span><br>
                                        <span class="menuContent" >{{l.description}}</span>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </header>
</template>

<script>
    import $ from 'jquery'
    import {clickContent1} from '../lib/until.js'
    import {listSecondLevelCatalogContent,listNavbarUrl} from '../serve/index.js'
    import {IsPC} from '../lib/until.js'
    export default {
        name: "Head",
        data() {
            return {
                menuShow: false,
                scroll: '',
                menuList: [],
                btnUrl: [{content: '',name: ''},{content: '',name: ''}],
                ispc: false
            }
        },
        mounted() {
            window.addEventListener('scroll', this.menu)
            this.listMenu()
            this.listNavbarUrl()
            this.ispc = IsPC()
        },
        methods: {
            clickRoute(name) {

                if (name == 'newDynamic') {
                    this.$router.push({name: name,query: {pageNum: 1}})
                    this.$store.dispatch("setDetail",name)
                } else {
                    this.$router.push({name: name})
                    this.$store.dispatch("setDetail",name)
                }
            },
            listNavbarUrl() {
                listNavbarUrl().then(res => {
                    if (res.resultCode == 200) {
                        this.btnUrl = res.data
                    }
                })
            },
            clickHref(href,code) {
                if (href) {
                    window.location.href = href
                    this.$store.dispatch("setDetail",'zhinengyingyong')
                    clickContent1(code)
                }
            },
            listMenu() {
                listSecondLevelCatalogContent({topCatalogCode: 'zhinengyingyong'}).then(res => {
                    if (res.resultCode == 200) {
                        this.menuList = res.data
                    }
                })
            },
            setTimeLeave2() {
                let self = this
                setTimeout(function () {
                    self.menuShow = false
                },500)
            },
            mouseover2() {
                this.menuShow = true;
            },
            /*监听滚动事件*/
            menu() {
                 this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                 if (this.scroll >= 100) {
                     $('#head').addClass('fixed')
                     $('#head').removeClass('static')
                 }else {
                     $('#head').addClass('static')
                     $('#head').removeClass('fixed')
                 }
            },
        }
    }
</script>

<style scoped>
    dt {
        font-weight:500;
        color:rgba(53,191,255,1);
        line-height:17px;
        font-size:12px;
        margin-top: 20px;
    }
    dd {
        margin: 30px 0;
        cursor: pointer;
    }
    .menuTitle {
        font-weight:700;
        color:rgba(0,0,0,0.85);
        line-height:16px;
        font-size: 10px;
    }
    .menuContent {
        font-weight:400;
        color:rgba(0,0,0,0.65);
        line-height:16px;
        font-size: 8px;
    }
    .inlineBlock {
        display: inline-block
    }
    .boxshadow {
        box-shadow:0px 0px 6px 0px rgba(0,0,0,0.08);
    }
    .static {
        position: static;
    }
    .fixed {
        position: fixed;
    }
</style>