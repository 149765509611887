<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img :src=imgList[0].imgUrl alt="" class="img-responsive" width="100%">
            <div class="container bgS box boxM">
                <p class="p1 font1">{{contents[0].title}}</p>
                <p class="p2 font2">{{contents[0].description}}</p>
            </div>
        </div>
        <div class="container-fluid mfut" style="text-align: center;">
            <div class="container" style="margin-bottom: 50px">
                <h2>{{mokuai[0].catalogName}}</h2>
                <div style="margin-left: 12px;">
                    <span class="hengxian"></span>
                </div>
                <div style="text-align: left;margin-left: 6px">
                    <span class="pcSpan">相关案例</span>
                </div>
                <div class="row" style="margin-top: 20px">
                    <div :class="{'col-sm-4': true, 'cursor': list.hyperLink}" style="margin: 10px 0" v-for="(list,index) in mokuai[0].contents" :key="index" @click="clickHerf(list.hyperLink)">
                        <div class="con1">
                            <img :src=list.imgUrl alt="" class="img-responsive">
                            <span>
                                <h2>{{list.title}}</h2>
                                <p>{{list.description}}</p>
                            </span>
                        </div>
                        <div class="pcDiv">
                            <span>{{list.title}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid" style="text-align: center;">
            <div class="container" style="margin-bottom: 100px">
                <h2>{{mokuai[1].catalogName}}</h2>
                <div style="margin-left: 12px;">
                    <span class="hengxian"></span>
                </div>
                <div style="text-align: left;margin-left: 6px">
                    <span class="pcSpan">相关案例</span>
                </div>
                <div class="row" style="margin-top: 20px">
                    <div :class="{'col-sm-4': true, 'cursor': list.hyperLink}" style="margin: 10px 0" v-for="(list,index) in mokuai[1].contents" :key="index" @click="clickHerf(list.hyperLink,list.code)">
                        <div class="con1">
                            <img :src=list.imgUrl alt="" class="img-responsive" width="100%">
                            <span>
                                <h2>{{list.title}}</h2>
                                <p>{{list.description}}</p>
                            </span>
                        </div>
                        <div class="pcDiv">
                            <span>{{list.title}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../components/header.vue'
    import Foot from '../components/footer.vue'
    import FootBot from '../components/footerBot.vue'
    import {getCarousel,listAllOnlineContentByTopParentCode} from '../serve/index.js'
    import {clickContent1} from '../lib/until.js'

    export default {
        metaInfo: {
            title: '学多多官网—信息化平台',
            meta: [
                {
                    name: 'keywords',
                    content: '校本课程定制；区本课程定制；校级平台定制；区级平台定制；信息化调研；学前资源库；心理测评；走班选课；教师研修；招生考试；'
                }
            ]
        },
        name: "subjectResources",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {
                imgList: [{imgUrl: ''}],
                topCatalogCode: 'xinxihuapingtai',
                mokuai: [{catalogName: ''},{catalogName: ''}],
                contents: [{title: '',description:''}]
            }
        },
        methods: {
            clickHerf(hyperLink,code) {
                if (hyperLink) {
                    window.location.href = hyperLink
                    clickContent1(code)
                }
            },
            getCarousel() {
                getCarousel({catalogCode: this.topCatalogCode}).then(res => {
                    if (res.resultCode == 200) {
                        if (res.data.records.length > 0) {
                            this.imgList = res.data.records
                        }
                    }
                })
            },
            listAllOnlineContentByTopParentCode() {
                listAllOnlineContentByTopParentCode({topCatalogCode: this.topCatalogCode}).then(res => {
                    if (res.resultCode == 200) {
                        this.mokuai = res.data.childs
                        this.contents = res.data.contents
                    }
                })
            },
        },
        mounted() {
            this.getCarousel()
            this.listAllOnlineContentByTopParentCode()
        }
    }
</script>

<style scoped>
    .con1 {
        position: relative;
        overflow:hidden;
    }
    .con1 span {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        background: #333;
        position: absolute;
        top: -100%;
        left: 0px;
        filter: Alpha(opacity=80);
        -moz-opacity: 0.8;
        opacity: 0.8;
        padding: 5px;
        -webkit-transition: top 0.5s ease;
        z-index: 99;
    }
    .con1 span h2 {
        height: 22px;
        color: #fff;
        font-size:18px;
        text-align: left;
        position: relative;
        top: -500px;
        -webkit-transition: top 1s ease;
        margin-top: 2px;
    }
    .con1 span p {
        line-height: 25px;
        font-size:14px;
        color: #fff;
        position: relative;
        text-align: left;
        top: -500px;
        -webkit-transition: top 1s ease;
    }
    .con1:hover span {
        top:0px;
    }
    .con1:hover span h2 {
        top: 0px;
    }
    .con1:hover span p {
        top: 0px;
    }
</style>