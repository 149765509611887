import { render, staticRenderFns } from "./subjectResources.vue?vue&type=template&id=7002be87&scoped=true"
import script from "./subjectResources.vue?vue&type=script&lang=js"
export * from "./subjectResources.vue?vue&type=script&lang=js"
import style0 from "./subjectResources.vue?vue&type=style&index=0&id=7002be87&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7002be87",
  null
  
)

export default component.exports