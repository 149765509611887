<!--
 * @Author: vince
 * @Date: 2021-02-09 10:30:35
 * @LastEditors: vince 273187189@qq.com
 * @LastEditTime: 2023-06-30 15:51:04
 * @Description: 
-->
<template>
    <div>
        <Head></Head>
        <div class="carousel slide" id="myCarousel" data-interval="3000">
            <ol class="carousel-indicators">
                <li data-target="#myCarousel" :data-slide-to=index :class="{ 'active': imgActive == index }"
                    v-for="(list, index) in imgList" :key="index" style="margin-left: 10px"></li>
            </ol>

            <div class="carousel-inner" @touchmove='setFlag' @touchend='change'>
                <div :class="{ 'item': true, 'active': imgActive == index, 'cursor': list.hyperLink }"
                     v-for="(list, index) in imgList" :key="index">
                    <img :src=list.imgUrl alt="First slide" width="100%" @click="clickImg(list.hyperLink, list.code)">
                </div>
            </div>


        </div>
        <div class="zhdn container">
            <h2>{{ mokuai.mokuai1.catalogName }}</h2>
            <div style="margin-left: 12px">
                <span class="hengxian"></span>
            </div>
            <p>{{ mokuai.mokuai1.subCatalogName }}</p>
            <img class="mind-box " src="../assets/img/image.svg" alt="" style="width: 100%;">
            <div class="mind-box " style="margin-top:40px;display: none;">
                <div :class="['card', 'card-' + i]" :key="i" v-for="c, i in catalogList">
                    <div class="card-title">
                        <span class="card-title-txt">{{ c.catalogName }}</span>
                    </div>
                    <ul class="c1">
                        <li :key="j" class="l1" v-for="item, j in c.catalogList">
                            <v-popover v-if="item.catalogList">
                                <span class="cn">{{ j + 1 }}.{{ item.catalogName }}</span>
                                <template slot="popover">
                                    <ul class="sub-ul">
                                        <li :key="k" v-for="cc, k in item.catalogList">{{ cc.catalogName }}</li>
                                    </ul>
                                </template>
                            </v-popover>
                            <span v-else >{{ item.catalogName }}</span>
                        </li>
                    </ul>
                    <template v-if="i == 0">
                        <div class="link link-1">
                            <span class="before"></span>
                            <span class="after"></span>
                        </div>
                        <div class="link link-2">
                            <span class="before"></span>
                            <span class="after"></span>
                        </div>
                        <div class="link link-3">
                            <span class="before"></span>
                            <span class="after"></span>
                        </div>
                        <div class="link link-4">
                            <span class="before"></span>
                            <span class="after"></span>
                        </div>
                    </template>

                </div>
            </div>
        </div>
        <div class="container-fluid bg2">
            <h2 class="mt100">{{ mokuai.mokuai2.catalogName }}</h2>
            <div style="margin-left: 12px">
                <span class="hengxian"></span>
            </div>
            <p>{{ mokuai.mokuai2.subCatalogName }}</p>
            <div class="container" style="margin-top:40px">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3"
                                 v-for="(list, index) in mokuai.mokuai2.childs.slice(0, 4)" :key="index"
                                 v-show="list.contents.length > 0">
                                <div class="cindex">0{{ index + 1 }}</div>
                                <div class="cname">{{ list.catalogName }}</div>
                                <ul class="app-list">
                                    <li :key="i" v-for="c, i in list.contents">
                                        <a class="app-name" :href="c.hyperLink" target="_blank">
                                            <img class="app-icon" :src="c.imgUrl" alt="">
                                            <span>{{ c.title }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <img style="display:block;width:100%" src="../assets/img/app_list.png" alt="">
                    </div>
                </div>

            </div>
            <!-- <div class="container" style="margin-bottom: 60px">
                <div class="row" style="min-height: 200px">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5  cursor m10" v-for="(content, index) in shouMk" :key="index">
                        <img width="100%" @click="clickImg(content.hyperLink, content.code)" :src=content.imgUrl alt=""
                             :class="{ 'img-responsive': true, 'cjborder': isOver == index }" @mouseover="isOver = index">
                    </div>
                    <div class="col-sm-1"></div>
                </div>
            </div> -->
            <div style="margin: 20px auto" v-show="length > 1">
                <div :class="{ 'mainDiv': true, 'mainDivActive': isActive1 == index - 1 }" v-for="index of length"
                     :key="index" @click="clickDiv(index - 1)"></div>
            </div>
        </div>
        <div class="container-fluid bg3">
            <h2>{{ mokuai.mokuai3.catalogName }}</h2>
            <div style="margin-left: 12px">
                <span class="hengxian"></span>
            </div>
            <p>{{ mokuai.mokuai3.subCatalogName }}</p>
            <div class="container" style="margin: 40px auto">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5  cursor" v-for="(item, index) in mokuai.mokuai3.contents.slice(0, 2)" :key="index">
                        <img @click="clickImg(item.hyperLink, item.code)" :src=item.imgUrl alt=""
                             class="img-responsive mainS" width="100%">
                    </div>
                    <div class="col-sm-1"></div>
                </div>
            </div>
        </div>
        <div class="container-fluid bg4">
            <h2 class="mt100">{{ mokuai.mokuai4.catalogName }}</h2>
            <div style="margin-left: 12px">
                <span class="hengxian"></span>
            </div>
            <div class="container" style="margin-bottom: 50px">
                <div class="row">
                    <div class="col-sm-3" v-for="(content, index) in mokuai.mokuai4.contents" :key="index"
                         style="margin-top: 20px">
                        <img :src=content.imgUrl alt="" class="img-responsive mainS" width="100%">
                    </div>
                </div>
            </div>
        </div>
        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
import $ from 'jquery'
import Head from '../components/header.vue'
import Foot from '../components/footer.vue'
import FootBot from '../components/footerBot.vue'
import { getCarousel, listAllOnlineContentByTopParentCode } from '../serve/index.js'
import { clickContent1 } from '../lib/until.js'
export default {
    metaInfo: {
        title: '学多多官网',
        meta: [
            {
                name: 'keywords',
                content: '学多多教育科技；上海学多多教育科技有限公司；学多多；绘本森林；慧测评；慧研修；绘教乐学；绘教慧学；小学生综合素质评价；智慧教育；教育信息化；'
            },
        ]
    },
    name: "Main",
    components: {
        Head, Foot, FootBot
    },
    mounted() {
        this.getCarousel();
        this.listAllOnlineContentByTopParentCode()
        $("#myCarousel").carousel('cycle');
    },
    watch: {
        showMk2: 'changeShouMk2'
    },
    methods: {
        setT() {
            let self = this
            if (this.length == 1) {
                clearInterval(this.startInterval)
                return
            }
            this.startInterval = setInterval(function () {
                if (self.isActive1 >= (self.length - 1)) {
                    self.isActive1 = -1
                    self.clickDiv(self.isActive1 + 1);
                } else {
                    self.clickDiv(self.isActive1 + 1);
                }
            }, 3000)
        },
        setFlag(event) {
            this.xpush.push(event.touches[0].pageX)
        },
        change() {
            var range = this.xpush[this.xpush.length - 1] - this.xpush[0];
            //如果水平滑动距离小于30，就不切换
            if (Math.abs(range) < 30) {
                return false;
            }
            var direction = range < 0 ? 'next' : 'prev';
            $("#myCarousel").carousel(direction);
            this.xpush = []
        },
        clickDiv(v) {
            this.shouMk = JSON.parse(JSON.stringify(this.showMk2.slice(v + v, v + v + 2)))
            this.isActive1 = v
            this.isOver = 0
        },
        changeShouMk2() {
            this.length = Math.ceil(this.showMk2.length / 2)
            this.shouMk = this.showMk2.slice(0, 2)
            clearInterval(this.startInterval)
            this.setT()
        },
        clickcj(v) {
            this.isActive = v
            this.isShow = v
            this.showMk2 = this.mokuai.mokuai2.childs[v].contents
        },
        getCarousel() {
            getCarousel({ catalogCode: this.topCatalogCode }).then(res => {
                if (res.resultCode == 200) {
                    if (res.data.records.length > 0) {
                        this.imgList = res.data.records
                    }
                }
            })
        },
        clickImg(hyperLink, code) {
            if (hyperLink) {
                window.location.href = hyperLink
                clickContent1(code)
            }
        },
        listAllOnlineContentByTopParentCode() {
            listAllOnlineContentByTopParentCode({ topCatalogCode: this.topCatalogCode }).then(res => {
                if (res.resultCode == 200) {
                    res.data.childs.forEach((item, index) => {
                        if (item.catalogCode == 'mokuai' + (index + 1)) {
                            this.mokuai['mokuai' + (index + 1)] = item
                        }
                    })
                    this.showMk2 = this.mokuai.mokuai2.childs[0].contents
                }
            })
        }
    },
    data() {
        return {
            catalogList: [
                {
                    catalogName: '慧分析',
                    catalogList: [{
                        catalogName: '用户统计',
                    }, {
                        catalogName: '活跃统计',
                    }, {
                        catalogName: '使用统计',
                    }, {
                        catalogName: '我的应用',
                    }]
                },
                {
                    catalogName: '学生成长',
                    catalogList: [
                        {
                            catalogName: '多多评价树',
                            catalogList: [{
                                catalogName: '多多评价树',
                            }, {
                                catalogName: '课堂评价',
                            }, {
                                catalogName: '行为规范',
                            }, {
                                catalogName: '班级巡检',
                            }, {
                                catalogName: '教师巡课',
                            }, {
                                catalogName: '报告',
                            }]
                        }, {
                            catalogName: '心理无忧',
                            catalogList: [
                                {
                                    catalogName: '量表测量',
                                }, {
                                    catalogName: '脑电波测量',
                                }, {
                                    catalogName: '心理机器人',
                                }
                            ]
                        }, {
                            catalogName: '慧画像',
                            catalogList: [
                                {
                                    catalogName: '学生个人画像',
                                }, {
                                    catalogName: '学生群体画像',
                                }, {
                                    catalogName: '数据采集系统',
                                }
                            ]
                        }, {
                            catalogName: '慧活动',
                            catalogList: [
                                {
                                    catalogName: '发起活动',
                                }, {
                                    catalogName: '发起活动',
                                }, {
                                    catalogName: '活动报告',
                                }
                            ]
                        }, {
                            catalogName: '绘本森林',
                            catalogList: [
                                {
                                    catalogName: '中文绘本',
                                }, {
                                    catalogName: '英文绘本',
                                }, {
                                    catalogName: '阅读报告',
                                }
                            ]
                        }, {
                            catalogName: '慧测评',
                            catalogList: [
                                {
                                    catalogName: '小学低学段阶段课后自主练习测评',
                                }, {
                                    catalogName: '游戏闯关式，情景式等多种形式在线测评',
                                }, {
                                    catalogName: '可视图表测评报告',
                                }
                            ]
                        }, {
                            catalogName: '慧游学',
                            catalogList: [
                                {
                                    catalogName: '热门展馆',
                                }, {
                                    catalogName: '附近展馆',
                                }, {
                                    catalogName: '发布活动',
                                }, {
                                    catalogName: '查看消息',
                                }
                            ]
                        }, {
                            catalogName: '支部通',
                            catalogList: [
                                {
                                    catalogName: '入党流程透明化',
                                }, {
                                    catalogName: '党建工作服务与管理',
                                }, {
                                    catalogName: '党日活动管理',
                                }
                            ]
                        }, {
                            catalogName: '慧导学',
                            catalogList: [
                                {
                                    catalogName: '师生故事',
                                }, {
                                    catalogName: '心理健康教育',
                                }, {
                                    catalogName: '引用资源管理',
                                }, {
                                    catalogName: '匹配管理',
                                }
                            ]
                        }, {
                            catalogName: '新生书院',
                            catalogList: [
                                {
                                    catalogName: '新生入学第一课',
                                }, {
                                    catalogName: '全面学习服务平台',
                                }, {
                                    catalogName: '适应大学生活',
                                }
                            ]
                        }, {
                            catalogName: '拓展课评价',
                            catalogList: [
                                {
                                    catalogName: '课程信息管理',
                                }, {
                                    catalogName: '学生报名服务',
                                }, {
                                    catalogName: '选课报表统计',
                                }
                            ]
                        }, {
                            catalogName: '学生成长手册',
                            catalogList: [
                                {
                                    catalogName: '评价管理',
                                }, {
                                    catalogName: '电子成长手册导出',
                                }, {
                                    catalogName: '品德发展与公民素养',
                                },
                                {
                                    catalogName: '修习课程与学业成绩',
                                }, {
                                    catalogName: '身心健康与艺术修养',
                                }, {
                                    catalogName: '创新精神与实践能力',
                                }, {
                                    catalogName: '学校特色活动',
                                }, {
                                    catalogName: '自我回顾',
                                }
                            ]
                        }]
                },
                {
                    catalogName: '学校管理',
                    catalogList: [
                        {
                            catalogName: '慧选课',
                            catalogList: [{
                                catalogName: '选课设置',
                            }, {
                                catalogName: '学生选课',
                            }, {
                                catalogName: '选课结果报告',
                            }]
                        }, {
                            catalogName: '知行校园',
                            catalogList: [
                                {
                                    catalogName: '消息',
                                }, {
                                    catalogName: '作业',
                                }, {
                                    catalogName: '校园动态',
                                }, {
                                    catalogName: '请假报修',
                                }, {
                                    catalogName: '请购',
                                }
                            ]
                        }, {
                            catalogName: '慧班牌',
                            catalogList: [
                                {
                                    catalogName: '班级文化建设与展示',
                                }, {
                                    catalogName: '营养午餐和考勤签到',
                                }, {
                                    catalogName: '课程表',
                                }
                            ]
                        }, {
                            catalogName: '慧活动',
                            catalogList: [
                                {
                                    catalogName: '发起活动',
                                }, {
                                    catalogName: '发起活动',
                                }, {
                                    catalogName: '活动报告',
                                }
                            ]
                        }, {
                            catalogName: '慧校牌',
                            catalogList: [
                                {
                                    catalogName: '学校风采',
                                }, {
                                    catalogName: '电子阅读',
                                }, {
                                    catalogName: '视频轮播',
                                }
                            ]
                        }, {
                            catalogName: '慧积分',
                            catalogList: [
                                {
                                    catalogName: '教师充值积分',
                                }, {
                                    catalogName: '学生兑换商品',
                                }, {
                                    catalogName: '商品管理',
                                }, {
                                    catalogName: '积分统计',
                                }
                            ]
                        }, {
                            catalogName: '慧用户',
                            catalogList: [
                                {
                                    catalogName: '学校管理',
                                }, {
                                    catalogName: '系统管理',
                                }, {
                                    catalogName: '用户管理',
                                }, {
                                    catalogName: '统一登录',
                                }, {
                                    catalogName: '管理账号',
                                }
                            ]
                        }]
                },
                {
                    catalogName: '课程建设',
                    catalogList: [
                        {
                            catalogName: '数字课程平台',
                            catalogList: [{
                                catalogName: '传统文化类',
                            }, {
                                catalogName: '红色教育',
                            }, {
                                catalogName: '科普百科',
                            }, {
                                catalogName: '综合实践',
                            }]
                        }, {
                            catalogName: '英语e乐园',
                            catalogList: [
                                {
                                    catalogName: '内容管理',
                                }, {
                                    catalogName: '游戏化闯关',
                                }
                            ]
                        }, {
                            catalogName: '学多多AR课程学习应用',
                            catalogList: [
                                {
                                    catalogName: '发布新闻',
                                }, {
                                    catalogName: '学习AR课程',
                                }, {
                                    catalogName: '布置作业',
                                }, {
                                    catalogName: '上传作品',
                                }
                            ]
                        }, {
                            catalogName: '国际理解课程',
                            catalogList: [
                                {
                                    catalogName: '国际视野',
                                }, {
                                    catalogName: '问题解决',
                                }
                            ]
                        }, {
                            catalogName: '慧校牌',
                            catalogList: [
                                {
                                    catalogName: '学校风采',
                                }, {
                                    catalogName: '电子阅读',
                                }, {
                                    catalogName: '视频轮播',
                                }
                            ]
                        }, {
                            catalogName: '慧京剧',
                            catalogList: [
                                {
                                    catalogName: '国际视野',
                                }, {
                                    catalogName: '问题解决',
                                }
                            ]
                        }, {
                            catalogName: '慧艺术',
                            catalogList: [
                                {
                                    catalogName: '梨园讲堂',
                                }, {
                                    catalogName: '京韵乐园',
                                }, {
                                    catalogName: '章节测试',
                                }, {
                                    catalogName: '单元小结',
                                }
                            ]
                        }]
                },
                {
                    catalogName: '教师发展',
                    catalogList: [
                        {
                            catalogName: '慧研修',
                            catalogList: [{
                                catalogName: '教研活动',
                            }, {
                                catalogName: '培训',
                            }, {
                                catalogName: '项目课程研究',
                            }, {
                                catalogName: '带教任务',
                            }, {
                                catalogName: '教师专业发展报告',
                            }]
                        }]
                }
            ],
            clearInterval: '',
            xpush: [],
            isShow: 0,
            isOver: 0,
            isActive: 0,
            isActive1: 0,
            imgList: [],
            imgActive: 0,
            length: '',
            showMk2: [],
            shouMk: [],
            mokuai: {
                mokuai1: {
                    contents: [
                        {
                            catalogCode: "",
                            catalogName: "",
                            code: "",
                            content: "",
                            description: "",
                            imgUrl: "",
                            parentCatalogCode: "",
                            sort: '',
                            status: "",
                            subCatalogName: "",
                            tags: "",
                            title: "",
                            type: "",
                        }
                    ]
                },
                mokuai2: {
                    childs: [{
                        contents: [
                            {
                                catalogCode: "",
                                catalogName: "",
                                code: "",
                                content: "",
                                description: "",
                                imgUrl: "",
                                parentCatalogCode: "",
                                sort: '',
                                status: "",
                                subCatalogName: "",
                                tags: "",
                                title: "",
                                type: "",
                            }
                        ]
                    }],
                    contents: [{
                        catalogCode: "",
                        catalogName: "",
                        code: "",
                        content: "",
                        description: "",
                        imgUrl: "",
                        parentCatalogCode: "",
                        sort: '',
                        status: "",
                        subCatalogName: "",
                        tags: "",
                        title: "",
                        type: "",
                    }]
                },
                mokuai3: {
                    contents: [{
                        catalogCode: "",
                        catalogName: "",
                        code: "",
                        content: "",
                        description: "",
                        imgUrl: "",
                        parentCatalogCode: "",
                        sort: '',
                        status: "",
                        subCatalogName: "",
                        tags: "",
                        title: "",
                        type: "",
                    }]
                },
                mokuai4: {
                    contents: [{
                        catalogCode: "",
                        catalogName: "",
                        code: "",
                        content: "",
                        description: "",
                        imgUrl: "",
                        parentCatalogCode: "",
                        sort: '',
                        status: "",
                        subCatalogName: "",
                        tags: "",
                        title: "",
                        type: "",
                    }]
                }
            },
            topCatalogCode: 'shouye'
        }
    }
}
</script>

<style scoped lang="scss">
.bg2 {
    background: url("../assets/img/bg2.png") no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    text-align: center;
    margin-top: 20px;

    .cindex {
        text-align: left;
        font-size: 32px;
        font-weight: bolder;
        color: #D1EDFC;
    }

    .cname {
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        color: #35BFFF;
    }

    .app-name {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
    }

    .app-icon {
        width: 26px;
        height: 26px;
        display: inline-block;
        object-fit: cover;
        border-radius:4px;
        margin-right:4px;
    }

    .app-list {
        padding: 6px 0px;
        list-style: none;
    }

    .app-name {
        padding: 6px 4px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        border: 1px solid transparent;
        &:hover {
            text-decoration: none;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            border: 1px solid #35BFFF;
        }
    }
}

.bg3 {
    background: url("../assets/img/bg3.png") no-repeat 100% 100%;
    text-align: center;
    margin-top: 20px;
}

.bg4 {
    background: url("../assets/img/bg4.png") no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    text-align: center;
    margin-top: 20px;
}

.mind-box {
    // background: url(../assets/img/mind-box-bg.png) no-repeat center / contain;
}

.c1 {
    // list-style-type: decimal;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    display: flex;
    flex-wrap: wrap;
    padding-left: 22px;
    .l1 {
        width: 33%;
        padding: 6px 0;
        text-align: left;
        cursor: pointer;

        .cn {
            position: relative;

            // &::after {
            //     padding-left: 6px;
            //     content: '▶';
            //     font-size: 10px;
            //     color: #5c5c5c
            // }
        }
        


        // margin-right: 26px;
    }


}

.link {
    position: absolute;
    width: 100px;
    height: 120px;
    border-radius: 12px;
    border: 3px solid #9BD1FB;

    .after {
        position: absolute;
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #9BD1FB;
    }

    .before {
        position: absolute;
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #9BD1FB;
    }

}

.link-1 {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 0;
    border-bottom: 0;
    left: -3px;
    bottom: 100%;

    .before {
        top: -6px;
        left: -5px;
    }

    .after {
        bottom: -5px;
        right: -6px;
    }
}

.link-2 {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top: 0;
    left: -3px;
    top: 100%;

    .before {
        top: -5px;
        right: -6px;
    }

    .after {
        bottom: -6px;
        left: -5px;
    }
}

.link-3 {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 0;
    border-bottom: 0;
    right: -3px;
    bottom: 100%;

    .before {
        top: -6px;
        right: -5px;
    }

    .after {
        bottom: -5px;
        left: -6px;
    }
}

.link-4 {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top: 0;
    right: -3px;
    top: 100%;

    .before {
        top: -5px;
        left: -6px;
    }

    .after {
        bottom: -6px;
        right: -5px;
    }

}

.sub-ul {
    padding-left: 24px;
    text-align: left;
}

.card-0 {
    color: #35B092;
    background: #E7F4F3;
    box-shadow: 4px 4px 0px 0px rgba(26, 101, 83, 0.1);
    border-radius: 12px;
    border: 3px solid #35B092;
    padding: 0 0 24px !important;
    z-index: 5;
    .card-title {
        transform: translateY(0) !important;
        position: relative;
        left: 0 !important;
        background-color: #35B092;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        margin-bottom: 16px;
        padding:6px 12px ;

    }
}

.card-1 {
    color: #4ED0B0;
    background: #EDFAF7 url(../assets/img/c-1.png) no-repeat right bottom/200px 145px;
    box-shadow: 0px 8px 2px 0px rgba(34, 133, 109, 0.2);

    .card-title {
        background: #4ED0B0;
        box-shadow: 4px 4px 0px 0px #1C7861;
        border-radius: 6px;
        border: 0px solid #4ED0B0;
    }




}

.card-2 {
    color: #2FB3EE;
    background: #EAF7FD url(../assets/img/c-2.png) no-repeat right bottom/200px 145px;
    box-shadow: 0px 8px 2px 0px rgba(18, 95, 129, 0.15);

    .card-title {
        background: #2FB3EE;
        box-shadow: 4px 4px 0px 0px #1A739A;
        border-radius: 6px;
        border: 1px solid #2FB3EE;
    }



}

.card-3 {
    color: #FFAC3E;
    background: #FFF6EB url(../assets/img/c-3.png) no-repeat right bottom/200px 145px;
    box-shadow: 0px 8px 2px 0px rgba(120, 76, 17, 0.1);

    .card-title {
        background: #FFAC3E;
        box-shadow: 4px 4px 0px 0px #935F19;
        border-radius: 6px;
        border: 1px solid #FFAC3E;
    }



}

.card-4 {
    color: #6A85F5;
    background: #F0F2FE url(../assets/img/c-4.png) no-repeat right bottom/200px 145px;
    box-shadow: 0px 8px 2px 0px rgba(57, 76, 155, 0.1);

    .card-title {
        background: #6A85F5;
        box-shadow: 4px 4px 0px 0px #30438F;
        border-radius: 6px;
        border: 1px solid #6A85F5;
    }



}

.card {
    position: relative;
    border: 1px solid currentColor;
    border-radius: 12px;
    margin-bottom: 30px;
    padding: 24px 0;
    min-height: 120px;
}







.card-title {
    background-color: currentColor;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    left: 22px;
    top: 0%;
    background-color: currentColor;
    transform: translateY(-50%);
    padding: 3px 12px;

    .card-title-txt {
        color: #FFFFFF;
    }
}
</style>