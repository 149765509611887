<!--
 * @Author: vince
 * @Date: 2023-06-26 14:17:12
 * @LastEditors: vince 273187189@qq.com
 * @LastEditTime: 2023-06-30 15:05:37
 * @Description: 
-->
<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/pjs_0.png" alt="" class="img-responsive" width="100%" >
            <div class="container bgS box boxM" >
                <p class="p1 font1" style="color: #42954C;">多多评价树</p>
                <p class="p2 font2">
                    多多评价树是中小学生综合素质评价平台，用于发现每一位学生的可塑点、生长点和发展点，运用五育、核心素养评价指标体系对学生进行诊断性评价和发展性评价，过程性评价和终结性评价，动态评价和静态评价，及时了解学生成长的优势和不足，并进行针对性指导。
                </p>
            </div>
        </div>
        <div class="container-fluid" style="margin-top:-58px">
            <div class="container">
                <div class="swiper ddpjs">
                    <!-- Additional required wrapper -->
                    <div class="swiper-wrapper">
                        <!-- Slides -->
                        <div class="swiper-slide " :key="i" v-for="n, i in list">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="item-name">
                                        <div class="item-title">{{ n.title }}</div>
                                        <ul class="item-ul">
                                            <li v-for="it, j in n.items" :key="j">{{ it }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <img style="width:100%" :src="n.src" alt="">
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- If we need pagination -->
                    <div class="swiper-pagination ddpjs-pagination"></div>

                    <!-- If we need navigation buttons -->
                    <!-- <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div> -->

                    <!-- If we need scrollbar -->
                    <!-- <div class="swiper-scrollbar"></div> -->
                </div>

            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';
import Head from '../../components/header.vue'
import Foot from '../../components/footer.vue'
import FootBot from '../../components/footerBot.vue'
import { IsPC } from '../../lib/until.js'

import src1 from '../../assets/img/pjs_1.png'
import src2 from '../../assets/img/pjs_2.png'
import src3 from '../../assets/img/pjs_3.png'
import src4 from '../../assets/img/pjs_4.png'
import src5 from '../../assets/img/pjs_5.png'


export default {
    metaInfo: {
        title: '学多多官网—多多数字基座',
        meta: [
            {
                name: 'keywords',
                content: ''
            }
        ]
    },
    name: "HuiBen",
    components: {
        Head, Foot, FootBot
    },
    mounted() {
        this.ispc = IsPC()
        const swiper = new Swiper('.swiper', {
            // Optional parameters
            // direction: 'vertical',
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: (index, className) => {
                    return '<span class="' + className + '">' + this.list[index].title + "</span>";
                },
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // And if we need scrollbar
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        });
    },
    data() {
        return {
            isActive1: 1,
            ispc: false,
            list: [{ src: src1, title: '学生评价', items: ['对学生行为进行评价', '查看学生评价报表', '自定义添加评价指标'] },
            { src: src2, title: '教师巡课', items: ['对教师上课情况进行评价', '可以个性化定制训课指标'] },
            { src: src3, title: '班级巡检', items: ['评价班级整体情况', '个性化定制配置评价指标'] },
            { src: src4, title: '拓展课评价', items: ['学生签到', '作业评价', '课堂评价'] },
            { src: src5, title: '成长手册', items: ['上海市中小学《学生成长手册》电子化版', '支持从教师、学生、家长录入信息，到学生成长手册的生成和导出', '·支持个性化定制'] }]
        }
    },
    methods: {
       

    }
}
</script>

<style lang="scss">
.ddpjs {
    width: 100%;
    height: 650px;
    padding-top: 100px;

    .item-name {
        background: url(../../assets/img/pjs_t.png) no-repeat 10% 20% / 75%;
    }

    .item-title {
        margin: 44px 50px;
        font-size: 26px;
        font-weight: 600;
        color: #212121;
    }

    .swiper-pagination-bullet {
        width: auto !important;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #5FC66F;
        padding: 8px 18px;
        background: #E4F8EA;
        border-radius: 4px;
        height: auto;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background-color: #5FC66F !important;
        color: white !important;
    }

    .item-ul {
        font-size: 16px;
        font-weight: 500;
        color: #272727;
        line-height: 2;
        margin-left: 35px;

        li {
            &::marker {
                font-size: 10px;
            }
        }
    }
}

.ddpjs-pagination {
    top: 8px !important;
    bottom: initial !important;
}
</style>