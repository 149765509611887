<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;min-height: 400px">
            <img src="../../assets/img/xddbg1.png" alt="" class="img-responsive" width="100%" @click="clickImg">
            <div class="container bgS box boxM" style="margin-top: 0">
                <p class="p1 font1">学多多</p>
                <p class="p2 font2">学多多是面向全国中小学师生的智慧云课堂。同步精选课内外优质资源，学生自主选择、个性化学习。作业一键布置，智能批改，动态学情， 教师及时掌握。学多多是高效学习、优质助教的掌中宝。
                </p>
            </div>
        </div>
        <div class="xddbg" style="margin-top: -8rem">
            <div class="container-fluid xddbg2">
                <div class="container" style="margin-bottom: 2rem">
                    <h2>优质课程资源</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian"></span>
                    </div>
                    <div class="row mt30">
                        <div class="col-sm-3 m10">
                            <img src="../../assets/img/zy1.png" alt="" class="img-responsive mainS" width="100%">
                        </div>
                        <div class="col-sm-3 m10">
                            <img src="../../assets/img/zy2.png" alt="" class="img-responsive mainS" width="100%">
                        </div>
                        <div class="col-sm-3 m10">
                            <img src="../../assets/img/zy3.png" alt="" class="img-responsive mainS" width="100%">
                        </div>
                        <div class="col-sm-3 m10">
                            <img src="../../assets/img/zy4.png" alt="" class="img-responsive mainS" width="100%">
                        </div>
                    </div>
                </div>
            </div>

             <div class="container-fluid xddbg3">
                <div class="container" style="margin-bottom: 2rem">
                    <h2>整本书阅读</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian"></span>
                    </div>
                    <div class="row mt30">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3">
                            <div class="iconDiv cursor" style="border-bottom: 1px #979797 solid" @click="active(1)">
                                <div style="margin: 0 0 30px 0;">
                                    <img src="../../assets/img/ydicona1.png" alt="" width="20px" class="img-responsive iconImg" v-if="isActive1 == 1">
                                    <img src="../../assets/img/ydicon1.png" alt="" width="20px" class="img-responsive iconImg" v-if="isActive1 != 1">
                                    <span :class="{'iconSpan': true,'iconActive': isActive1 == 1}">教材推荐书目，经典趣味必读</span>
                                    <p class="iconP"> 教材推荐、经典趣味必读书目</p>
                                </div>
                            </div>
                            <div class="iconDiv cursor" style="margin: 30px 0" @click="active(2)">
                                <img src="../../assets/img/ydicon2.png" alt="" width="20px" class="img-responsive iconImg" v-if="isActive1 != 2">
                                <img src="../../assets/img/ydicona2.png" alt="" width="20px" class="img-responsive iconImg" v-if="isActive1 == 2">
                                <span :class="{'iconSpan': true,'iconActive': isActive1 == 2}">阅读前、中、后“环环相扣”</span>
                                <p class="iconP">做到阅读前导读、阅读中思考、阅读后交流，有针对性提升阅读能力。</p>
                            </div>
                            <div class="iconDiv cursor" style="border-top: 1px #979797 solid" @click="active(3)">
                                <div  style="margin: 30px 0 0 0;">
                                    <img src="../../assets/img/ydicon3.png" alt="" width="20px" class="img-responsive iconImg" v-if="isActive1 != 3">
                                    <img src="../../assets/img/ydicona3.png" alt="" width="20px" class="img-responsive iconImg" v-if="isActive1 == 3">
                                    <span :class="{'iconSpan': true,'iconActive': isActive1 == 3}">阅读效果全面测评</span>
                                    <p class="iconP">多样化阅读测评，识记、理解、表达、评鉴、创造，阅读效果全面评估。</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-7" style="margin-left: 50px">
                            <img :src="src" alt="" class="img-responsive imgsh">
                        </div>
                    </div>
                </div>
            </div> 

            <div class="container-fluid xddbg4">
                <div class="container" style="margin-bottom: 2rem">
                    <h2>多样化练习</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian"></span>
                    </div>
                    <div class="row mt30">
                        <div class="col-sm-3" style="text-align: left">
                            <p class="lxp">精选练习，一键推送，智能批改，同时支撑学校自主设计习题，方式多样，灵活控制。</p>
                            <span class="lxspan">左图：学生端 | 右图：教师端</span>
                        </div>
                        <div class="col-sm-1"></div>
                        <div class="col-sm-4">
                            <img src="../../assets/img/lphone.png" alt="" class="img-responsive" width="100%">
                        </div>
                        <div class="col-sm-4">
                            <img src="../../assets/img/rphone.png" alt="" class="img-responsive" width="100%">
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid xddbg5">
                <div class="container" style="margin-bottom: 2rem">
                    <h2>个性化权限控制</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian"></span>
                    </div>
                    <p>教师可在PC端管理账号、资源、组卷等，还可设置针对本校开放的专享资源。</p>
                    <div class="row mt30">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-2">
                            <img src="../../assets/img/ljs.png" alt="" class="img-responsive" style="margin-top: 80px" width="100%">
                        </div>
                        <div class="col-sm-3 col-sm-offset-2">
                            <img src="../../assets/img/rdn.png" alt="" class="img-responsive" width="100%">
                        </div>
                        <div class="col-sm-2"></div>
                    </div>
                </div>
            </div>

            <div class="container-fluid xddbg6">
                <div class="container" style="margin-bottom: 2rem">
                    <h2>学情分析</h2>
                    <div style="margin-left: 12px">
                        <span class="hengxian"></span>
                    </div>
                    <div class="row mt30">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-5">
                            <img :src=xqsrc alt="" class="img-responsive" width="100%">
                        </div>
                        <div class="col-sm-4" style="margin-top: 30px">
                            <img :src=arsrc1 alt="" :class="{'img-responsive': true,'cursor': true}" style="margin: 20px 0" @click="activexq(1)" width="100%">
                            <img :src=arsrc2 alt="" :class="{'img-responsive': true,'cursor': true}" style="margin: 20px 0" @click="activexq(2)" width="100%">
                            <img :src=arsrc3 alt="" :class="{'img-responsive': true,'cursor': true}" style="margin: 20px 0" @click="activexq(3)" width="100%">
                        </div>
                        <div class="col-sm-2"></div>
                    </div>
                </div>
            </div>
            <div class="container-fluid xddbg7">
                <div class="container" style="margin-bottom: 2rem">
                    <img src="../../assets/img/iosicon.png" alt="" style="margin-top: 100px" width="20%">
                    <p class="bg7P">学多多智慧云课堂还有更多精彩内容等着您，打开App Store或安卓应用市场下载即可体验！</p>
                </div>
            </div>
        </div>
        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../../components/header.vue'
    import Foot from '../../components/footer.vue'
    import FootBot from '../../components/footerBot.vue'
    import src1 from '../../assets/img/yd1.png'
    import src2 from '../../assets/img/yd2.png'
    import src3 from '../../assets/img/yd3.png'
    import src4 from '../../assets/img/lxs1.png'
    import src5 from '../../assets/img/ljs2.png'
    import src6 from '../../assets/img/lct3.png'
    import rsrc1 from '../../assets/img/rxs1.png'
    import rsrc2 from '../../assets/img/rjs2.png'
    import rsrc3 from '../../assets/img/rct3.png'
    import rsrca1 from '../../assets/img/rxsa1.png'
    import rsrca2 from '../../assets/img/rjsa2.png'
    import rsrca3 from '../../assets/img/rcta3.png'
    import {IsPC} from '../../lib/until.js'

    export default {
        metaInfo: {
            title: '学多多官网—学多多慧云课堂',
            meta: [
                {
                    name: 'keywords',
                    content: '国学经典；小学教育；K12；移动课堂；课本同步微课；中英文有声绘本故事；课本点读；古诗词学习,牛津阅读树学多多教育科技；上海学多多教育科技有限公司；学多多；绘本森林；慧测评；慧研修；绘教乐学；绘教慧学；小学生综合素,剑桥少儿英语,朗文；奥数；语文,数学,翻转课堂；教学评价,互动；学习平台；音乐；编程,学霸笔记,口语评测,语文听写作业,口算速算,在线作业；'
                },
            ]
        },
        name: "Xdd",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {
                isActive1: 1,
                isActive2: 1,
                src: src1,
                xqsrc: src4,
                arsrc1: rsrca1,
                arsrc2: rsrc2,
                arsrc3: rsrc3,
                ispc: false
            }
        },
        mounted() {            
            this.ispc = IsPC()
        },
        methods: {
            clickImg() {
              if (!this.ispc) {
                  window.location.href = 'http://www.xueduoduo.com/hyun/index.html'
              }
            },
            active(v) {
                if (v == 1) {
                    this.isActive1 = 1;
                    this.src = src1
                } else if (v == 2) {
                    this.isActive1 = 2;
                    this.src = src2
                } else if (v == 3) {
                    this.isActive1 = 3;
                    this.src = src3
                }
            },
            activexq(v) {
              if (v == 1) {
                  this.isActive2 = 1;
                  this.xqsrc = src4;
                  this.arsrc1 = rsrca1;
                  this.arsrc2 = rsrc2;
                  this.arsrc3 = rsrc3;
              }else if (v == 2) {
                  this.isActive2 = 2;
                  this.xqsrc = src5;
                  this.arsrc1 = rsrc1;
                  this.arsrc2 = rsrca2;
                  this.arsrc3 = rsrc3;
              }else if (v == 3) {
                  this.isActive2 = 3;
                  this.xqsrc = src6;
                  this.arsrc1 = rsrc1;
                  this.arsrc2 = rsrc2;
                  this.arsrc3 = rsrca3;
                }
            },
        }
    }
</script>

<style scoped>
    .xddbg {
        background:
                url("../../assets/img/xddbg2.png") no-repeat 0 0,
                url("../../assets/img/xddbg3.png") no-repeat 0 12%,
                url("../../assets/img/xddbg4.png") no-repeat 0 43%,
                url("../../assets/img/xddbg5.png") no-repeat 0 74%,
                url("../../assets/img/xddbg6.png") no-repeat 0 80%;
        -webkit-background-size: contain;
        background-size: contain;
    }
    .xddbg2 {
        text-align: center;
    }

    .xddbg3 {
        text-align: center;
        margin-top: 54px;
    }

    .xddbg4 {
        text-align: center;
        margin-top: 54px;
    }

    .xddbg5 {
        text-align: center;
        margin-top: 54px;
    }
    .xddbg6 {
        text-align: center;
        margin-top: 54px;
    }
    .xddbg7 {
        background: url("../../assets/img/xddbg7.png") no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        text-align: center;
        margin-top: 54px;
    }

</style>