<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/zpbj1.png" alt="" class="img-responsive" width="100%">
            <div class="container bgS box boxM" style="margin-top: 80px;">
                <p class="p1 font1">综合素质评价</p>
                <p class="p2 font2">学多多综合素质评价系统通过物联网技术的云手环等智能硬件以及智能手机、平板电脑等移动应用和PC端软件来收集、记录并分析学生综合素质各方面的数据，采用等第制评价等科学的评价手段， 展示学生在学业素养、 品行素养、 艺术素养、 身心健康等方面的综合表现。</p>
            </div>
        </div>
        <div class="container-fluid" style="text-align: center;margin-top: -6rem">
            <div class="container" style="margin-bottom: 100px">
                <h2>综合素质评价</h2>
                <div style="margin-left: 12px">
                    <span class="hengxian pcoror"></span>
                </div>
                <ul class="list-inline bg2ul">
                    <li v-for="(li,index) in list" :key="index">
                        <div :class="{'btn':true,'zpBtn': true,'m10': true,'zpBtnActive': isActive == index + 1}" @click="clickcj(index + 1)">{{li}}</div>
                    </li>
                </ul>
                <img :src=src alt="" class="img-responsive" style="margin-top: 50px;margin-left: auto;margin-right: auto">
                <div style="margin: 20px auto">
                    <div :class="{'zpDiv': true,'zpDivActive': isActive == index + 1}" @click="clickcj(index + 1)" v-for="(li,index) in list" :key="index"></div>
                </div>
            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../../components/header.vue'
    import Foot from '../../components/footer.vue'
    import FootBot from '../../components/footerBot.vue'
    import src1 from '../../assets/img/zpimg1.png'
    import src2 from '../../assets/img/zpimg2.png'
    import src3 from '../../assets/img/zpimg3.png'
    import src4 from '../../assets/img/zpimg4.png'
    import src5 from '../../assets/img/zpimg5.png'
    import src6 from '../../assets/img/zpimg6.png'

    export default {
        metaInfo: {
            title: '学多多官网—综合素质评价',
            meta: [
                {
                    name: 'keywords',
                    content: '小学生综合素质评价；学业素养；品行素养；身心健康；艺术素养；学生成长档案；学生全面发展；核心素养；评价指标；学习兴趣；学习习惯；学业成果；'
                },
            ]
        },
        name: "Zp",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {
                isActive: 1,
                src: src1,
                list: ['评价体系','数据采集','评价方式','数据分析','班牌展示','助力发展']
            }
        },
        methods: {
            clickcj(val) {
                if (val == 1) {
                    this.src = src1
                    this.isActive = 1
                } else if (val == 2) {
                    this.src = src2
                    this.isActive = 2
                } else if (val == 3) {
                    this.src = src3
                    this.isActive = 3
                }else if (val == 4) {
                    this.src = src4
                    this.isActive = 4
                }else if (val == 5) {
                    this.src = src5
                    this.isActive = 5
                }else if (val == 6) {
                    this.src = src6
                    this.isActive = 6
                }
            }
        }
    }
</script>

<style scoped>

</style>