<!--
 * @Author: vince
 * @Date: 2021-02-09 10:30:35
 * @LastEditors: vince 273187189@qq.com
 * @LastEditTime: 2022-07-08 11:30:36
 * @Description: 
-->
<template>
    <div class="container-fluid bg6">
        <div class="row foot-bot-font">
            <div class="col-sm-12 mauto" style="margin-top: 15px">
                <a href="http://beian.miit.gov.cn/" target="_blank" class="foot-bot-a">
                    <span style="color: #F9F9F9;font-size: 14px;">上海学多多教育科技有限公司 © 版权所有   沪ICP备14016178号</span>
                </a>
            </div>
            <div class="col-sm-12 mauto" style="margin-bottom: 15px">
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014114" target="_blank" class="foot-bot-a">
                <img src="../assets/img/footbot.png" alt="" width="20">
                <span style="margin-left: 10px;color: #F9F9F9;font-size: 14px;">沪公网安备 31011202014114</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterBot"
    }
</script>

<style scoped>

</style>