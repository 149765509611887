import axios from 'axios';
import qs from 'qs';
import Vue from 'vue'
// export function getData(urlData, paramData) {
//     return new Promise((resolve, reject) => {
//         axios.get(urlData, qs.stringify(paramData)).then(function(response) {
//             if (!!response.data && response.data.resultCode === '0') {
//                 resolve(response.data);
//             } else {
//                 reject(response.data.message);
//             }
//         }).catch(function(error) {
//             reject(error);
//         });
//     });
// }
let debug = Vue.config.devtools;
//响应拦截
axios.interceptors.response.use((res) => {

    // if (typeof res.request.response == 'string') {
    //     if (JSON.parse(res.request.response).resultCode === 20001) {
    //         ElementUI.Message.error('登录超时，请重新登录')
    //         if (debug) {
    //             window.location.href = '/admin.html'
    //         } else {
    //             window.location.href = '/mfl-admin/admin.html'
    //         }
    //     }
    // }

    return res
}, function(err) {
    return Promise.reject(error)
})

export function getData(urlData, paramData, responseType) {
    return new Promise((resolve, reject) => {
        let o = {
            params: paramData
        }
        if (responseType) {
            o.responseType = responseType
        }

        axios.get(urlData, o).then(function(response) {
                if (!!response.data) {
                    resolve(response.data);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(function(error) {
                reject(error);
            });

    });
}

export function postJson(urlData, paramData,config){
    return new Promise((resolve, reject) => {
        axios.post(urlData, paramData,config).then(function(response) {
            if (!!response.data) {
                resolve(response.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function(error) {
            reject(error);
        });
    });
}

export function deleteData(urlData, paramData) {
    return new Promise((resolve, reject) => {
        let o = {
            params: paramData
        }
        axios.delete(urlData,o)
            .then(function(response) {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            }).catch(function(error) {
                reject(error);
            });
    });
}

export function postData(urlData, paramData,config) {
    return new Promise((resolve, reject) => {
        axios.post(urlData, qs.stringify(paramData),config)
            .then(function(response) {
                if (!!response.data) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            }).catch(function(error) {
                reject(error);
            });
    });
}

// export function postData1(urlData, paramData,config) {
//     return new Promise((resolve, reject) => {
//         axios.post(urlData, paramData,config)
//             .then(function(response) {
//                 if (!!response.data) {
//                     resolve(response.data);
//                 } else {
//                     reject(response.data);
//                 }
//             }).catch(function(error) {
//             reject(error);
//         });
//     });
// }
// export function postData(urlData, paramData) {
//     return new Promise((resolve, reject) => {
//         axios.post(urlData, qs.stringify(paramData))
//         .then(function(response) {
//             if (!!response.data && response.data.resultCode === '0') {
//                 resolve(response.data);
//             } else {
//                 reject(response.data);
//             }
//         }).catch(function(error) {
//             reject(error);
//         });
//     });
// }
