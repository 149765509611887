<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../assets/img/ndbg1.png" alt="" class="img-responsive" width="100%">
        </div>
        <div style="background-color: #F8F9FD;padding-top: 20px;padding-bottom: 50px">
            <div class="container rdBg">
                <span  @click="back" class="cursor">媒体报道 </span>
                <span style="color: #1AADFF"> / </span>
                <span style="color: #1AADFF"> 报道详情</span>
            </div>
            <div class="container ql-editor" style="background-color: #fff;min-height: 800px;padding-top: 20px" v-html="message">

            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../components/header.vue'
    import Foot from '../components/footer.vue'
    import FootBot from '../components/footerBot.vue'
    import {getContentByContentCode} from '../serve/index.js'

    export default {
        metaInfo: {
            title: '学多多官网—媒体报道'
        },
        name: "reportDetail",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {
                message: ''
            }
        },
        methods: {
            getContentByContentCode() {
                getContentByContentCode({code: this.$route.params.code}).then(res => {
                    if (res.resultCode == 200) {
                        this.message = res.data
                    }else {

                    }
                })
            },
            back() {
                this.$router.push({name: 'newDynamic',query: this.$route.params.pageNum})
            }
        },
        mounted() {
            this.getContentByContentCode()
        },
        updated() {
            $('.container').find('img').css('max-width', '100%');
        },
    }
</script>

<style scoped>

</style>