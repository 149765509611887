<!--
 * @Author: vince
 * @Date: 2021-02-09 11:09:26
 * @LastEditors: vince
 * @LastEditTime: 2021-02-09 11:50:12
 * @Description: 
-->
<template>
    <div>
        <Head></Head>
        
        <iframe style="display:block" width="100%" height="700" src="http://www.51weixiao.net/weschool/LoginInPage.html" frameborder="0"></iframe>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../components/header.vue'
    import Foot from '../components/footer.vue'
    import FootBot from '../components/footerBot.vue'

    export default {
        metaInfo: {
            title: '学多多官网—信息化平台',
            meta: [
                {
                    name: 'keywords',
                    content: '校本课程定制；区本课程定制；校级平台定制；区级平台定制；信息化调研；学前资源库；心理测评；走班选课；教师研修；招生考试；'
                }
            ]
        },
        name: "wisdomClass",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {
                
            }
        },
       
    }
</script>

<style scoped>
    .con1 {
        position: relative;
        overflow:hidden;
    }
    .con1 span {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        background: #333;
        position: absolute;
        top: -100%;
        left: 0px;
        filter: Alpha(opacity=80);
        -moz-opacity: 0.8;
        opacity: 0.8;
        padding: 5px;
        -webkit-transition: top 0.5s ease;
        z-index: 99;
    }
    .con1 span h2 {
        height: 22px;
        color: #fff;
        font-size:18px;
        text-align: left;
        position: relative;
        top: -500px;
        -webkit-transition: top 1s ease;
        margin-top: 2px;
    }
    .con1 span p {
        line-height: 25px;
        font-size:14px;
        color: #fff;
        position: relative;
        text-align: left;
        top: -500px;
        -webkit-transition: top 1s ease;
    }
    .con1:hover span {
        top:0px;
    }
    .con1:hover span h2 {
        top: 0px;
    }
    .con1:hover span p {
        top: 0px;
    }
</style>