<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img :src=imgList[0].imgUrl alt="" class="img-responsive" width="100%">
            <div class="container bgS box boxM">
                <p class="p1 font1">{{ contents[0].title }}</p>
                <p class="p2 font2">{{ contents[0].description }}</p>
            </div>
        </div>
        <div class="container-fluid mfut" style="text-align: center;">
            <div class="container" style="margin-bottom: 100px">
                <ul class="list-inline bg2ul">
                    <li v-for="(li, index) in mokuai" :key="index">
                        <div :class="{ 'btn': true, 'srBtn': true, 'm10': true, 'srBtnActive': isActive == index }"
                             @click="clickcj(index, li)"><em
                                :class="{ 'to-bottom': isActive == index }"></em>{{ li.catalogName }}</div>
                    </li>
                </ul>
                <div v-for="(mo, index) in mokuai" :key="index">
                    <ul class="list-inline bg2ul" v-if="isShow == index">
                        <li>
                            <div :class="{ 'btn': true, 'srLabel': true, 'srBtnActive': isLabelActive == 1 }"
                                 @click="clikcLabel(1, mo.catalogCode)" v-if="mo.childs.length > 0">全部</div>
                        </li>
                        <li v-for="(li, index1) in mo.childs" :key="index1" v-show="li.contents.length > 0">
                            <div :class="{ 'btn': true, 'srLabel': true, 'srBtnActive': isLabelActive == index1 + 2 }"
                                 @click="clikcLabel(index1 + 2, null, li.catalogCode)">{{ li.catalogName }}</div>
                        </li>
                    </ul>
                </div>

                <div class="row" style="margin-top: 20px">
                    <div :class="{ 'col-sm-4': true, 'cursor': list.hyperLink }" style="margin-top: 20px"
                         v-for="(list, index) in ti" :key="index" @click="clickHerf(list.hyperLink, list.code)">
                        <div class="con">
                            <div class="img-box">
                                <div class="img-wrap">
                                    <img :src=list.imgUrl alt="">
                                </div>
                            </div>
                            <span>
                                <h2>{{ list.title }}</h2>
                                <p>{{ list.description }}</p>
                            </span>
                        </div>
                        <span class="srSpan">{{ list.title }}</span>
                        <div class="inlineBlock labelDiv" v-if="list.tags">
                            {{ list.tags }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container moreDiv" v-if="pages > 1" @click="clickMore">
            <span class="moreSpan">查看更多</span>
        </div>


        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
import Head from '../components/header.vue'
import Foot from '../components/footer.vue'
import FootBot from '../components/footerBot.vue'
import { getCarousel, listAllOnlineContentByTopParentCode, listContentInfo } from '../serve/index.js'
import { clickContent1 } from '../lib/until.js'
export default {
    metaInfo: {
        title: '学多多官网—课程资源',
        meta: [
            {
                name: 'keywords',
                content: '精品微课；专题课程；富媒体课程；道德法制；传统文化；科普百科；生命健康；职业规划；安全教育；综合实践；学科学习；阅读素养；研学游学；STEM及创客；环保课程；'
            }
        ]
    },
    name: "subjectResources",
    components: {
        Head, Foot, FootBot
    },
    data() {
        return {
            isShow: 0,
            isActive: 0,
            isLabelActive: 1,
            topCatalogCode: 'kechengziyuan',
            imgList: [{ imgUrl: '' }],
            mokuai: [],
            pageSize: 9,
            pageNum: 1,
            contents: [{ title: '', description: '' }],
            ti: [],
            parentCatalogCode: '',
            catalogCode: '',
            pages: 1,
            count: 1
        }
    },
    mounted() {
        this.getCarousel()
        this.listAllOnlineContentByTopParentCode()
    },
    methods: {
        clickHerf(hyperLink, code) {
            if (hyperLink) {
                window.location.href = hyperLink
                clickContent1(code)
            }
        },
        clickMore() {
            this.count = this.count + 1
            this.pageSize = this.count * 9
            this.listContentInfo(this.parentCatalogCode, this.catalogCode)
        },
        clikcLabel(index, parentCatalogCode, catalogCode) {
            this.count = 1
            this.pageSize = 9
            this.isLabelActive = index
            this.listContentInfo(parentCatalogCode, catalogCode)
        },
        listContentInfo(parentCatalogCode, catalogCode) {
            this.parentCatalogCode = parentCatalogCode || null
            this.catalogCode = catalogCode || null
            listContentInfo({
                parentCatalogCode: parentCatalogCode || null, catalogCode: catalogCode || null, pageSize: this.pageSize,
                pageNum: this.pageNum
            }).then(res => {
                if (res.resultCode == 200) {
                    this.ti = res.data.records
                    this.pages = res.data.pages
                }

            })
        },
        getCarousel() {
            getCarousel({ catalogCode: this.topCatalogCode }).then(res => {
                if (res.resultCode == 200) {
                    if (res.data.records.length > 0) {
                        this.imgList = res.data.records
                    }

                }
            })
        },
        listAllOnlineContentByTopParentCode() {
            listAllOnlineContentByTopParentCode({ topCatalogCode: this.topCatalogCode }).then(res => {
                if (res.resultCode == 200) {
                    this.mokuai = res.data.childs
                    this.contents = res.data.contents
                    this.clickcj(0, this.mokuai[0])
                }
            })
        },
        clickcj(v, li) {
            this.isActive = v
            this.isShow = v
            this.count = 1
            this.pageSize = 9
            if (li.childs.length > 0) {
                this.listContentInfo(li.catalogCode)
            } else {
                this.listContentInfo(null, li.catalogCode)
            }
        },
    }
}
</script>

<style scoped>
.con {
    position: relative;
    overflow: hidden;
}

.con .img-box{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 78%;
}
.con .img-wrap{
    position: absolute;
    width: 100%;
    height: 100%;
}

.con .img-wrap img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.con span {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    background: #333;
    position: absolute;
    top: -100%;
    left: 0px;
    filter: Alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    padding: 5px;
    -webkit-transition: top 0.5s ease;
    z-index: 99;
}

.con span h2 {
    height: 22px;
    color: #fff;
    font-size: 18px;
    text-align: left;
    position: relative;
    top: -500px;
    -webkit-transition: top 1s ease;
    margin-top: 10px;
}

.con span p {
    line-height: 25px;
    font-size: 14px;
    color: #fff;
    position: relative;
    text-align: left;
    top: -500px;
    -webkit-transition: top 1s ease;
}

.con:hover span {
    top: 0px;
}

.con:hover span h2 {
    top: 0px;
}

.con:hover span p {
    top: 0px;
}</style>