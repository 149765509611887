<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/hjbg1.png" alt="" class="img-responsive" width="100%">
            <div class="container bgS box boxM" style="margin-top: 80px;">
                <p class="p1 font1">绘教乐学</p>
                <p class="p2 font2">绘教乐学是一款以绘本森林阅读平台为基石、 以科学的教学理论为指导、 优质的教学资源为核心、 提升教师的专业素养及教学能力为目标，旨在为教师提供专业的绘本教学指导、 丰富的绘本教学课件、 系统的绘本教学课程、 以及优质的绘本书单推送等服务的绘本教学资源平台。</p>
                <p class="p2 font2">让教学有深度， 让课程更精彩！</p>
            </div>
        </div>
        <div class="hjbg" style="margin-top: -10rem">
            <div class="container-fluid hjbg3">
                <div class="container" style="margin-bottom: 6rem">
                    <div class="row">
                        <div class="col-sm-4 col-sm-offset-1" style="text-align: left">
                            <div class="row">
                                <div class="col-sm-2" style="margin-top: 4rem">
                                    <img src="../../assets/img/hjicon1.png" width="53" style="display: inline">
                                </div>
                                <div class="col-sm-8 col-sm-offset-1" style="margin-top: 4rem">
                                    <span class="hjh">优质绘本教学资源</span><br>
                                    <span class="hjh">让课堂更丰富</span>
                                    <p class="hjp">幼儿园与五大领域结合，小学与 学科教学融合创新，优质资源共享。</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <img src="../../assets/img/hj1.png" alt="" class="img-responsive">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid hjbg3">
                <div class="container" style="margin-bottom: 6rem">
                    <div class="row">
                        <div class="col-sm-6">
                            <img src="../../assets/img/hj2.png" alt="" class="img-responsive">
                        </div>
                        <div class="col-sm-4 col-sm-offset-1" style="text-align: left">
                            <div class="row">
                                <div class="col-sm-2" style="margin-top: 4rem">
                                    <img src="../../assets/img/hjicon2.png" width="53" style="display: inline">
                                </div>
                                <div class="col-sm-8 col-sm-offset-1" style="margin-top: 4rem">
                                    <span class="hjh">阅读书单一键推送</span><br>
                                    <span class="hjh">学生实时自主阅读</span>
                                    <p class="hjp">精选各类主题书单，教师可一键 推送至任教班级，学生可随时随 地完成阅读。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid hjbg3">
                <div class="container" style="margin-bottom: 6rem">
                    <div class="row">
                        <div class="col-sm-4 col-sm-offset-1" style="text-align: left">
                            <div class="row">
                                <div class="col-sm-2" style="margin-top: 4rem">
                                    <img src="../../assets/img/hjicon3.png" width="53" style="display: inline">
                                </div>
                                <div class="col-sm-8 col-sm-offset-1" style="margin-top: 4rem">
                                    <span class="hjh">海量绘本详情介绍</span><br>
                                    <span class="hjh">教师轻松挑选收藏</span>
                                    <p class="hjp">丰富的绘本详情介绍，供教师轻 松挑选，节时省力，方便快捷。</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <img src="../../assets/img/hj3.png" alt="" class="img-responsive">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid hjbg3">
                <div class="container" style="margin-bottom: 6rem">
                    <div class="row">
                        <div class="col-sm-6">
                            <img src="../../assets/img/hj4.png" alt="" class="img-responsive">
                        </div>
                        <div class="col-sm-4 col-sm-offset-1" style="text-align: left">
                            <div class="row">
                                <div class="col-sm-2">
                                    <img src="../../assets/img/hjicon4.png" width="53" style="display: inline;margin-top: 4rem">
                                </div>
                                <div class="col-sm-8 col-sm-offset-1" style="margin-top: 4rem">
                                    <span class="hjh">精品文章快乐分享</span><br>
                                    <span class="hjh">精彩活动实时呈现</span>
                                    <p class="hjp">精挑细选优质文章，供教师阅读 学习；精彩活动实时呈现，以便 教师了解和参与。</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
    import Head from '../../components/header.vue'
    import Foot from '../../components/footer.vue'
    import FootBot from '../../components/footerBot.vue'

    export default {
        metaInfo: {
            title: '学多多官网—绘教慧学',
            meta: [
                {
                    name: 'keywords',
                    content: '绘教慧学；绘教乐学；绘本教学；绘本教学材料包；绘本阅读；绘本课程；幼儿园五大领域；学科融合；'
                 }
            ]
        },
        name: "HuiJiao",
        components: {
            Head,Foot,FootBot
        },
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>

<style scoped>
    .hjbg {
        background: url("../../assets/img/hjbg3.png") no-repeat 0 0,
        url("../../assets/img/hjbg4.png") no-repeat 0 90%;
        -webkit-background-size: contain;
        background-size: contain;
    }
    .hjbg3 {
        margin-top: 54px;
    }


</style>