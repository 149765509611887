<template>
    <div class="container-fluid bg5">
        <div class="container" style="margin-top: 65px;margin-bottom: 30px">
            <div class="row">
                <div class="col-sm-2">
                    <img src="../assets/img/footlogo.png" alt="" class="img-responsive" width="77">
                </div>
                <div class="col-sm-2">
                    <dl>
                        <dt>学多多</dt>
                        <dd><span class="span" @click="$router.push({name: 'Main'})">首页</span></dd>
                        <dd><span class="span" @click="$router.push({name: 'subjectResources'})">课程资源</span></dd>
                        <dd><span class="span" @click="$router.push({name: 'pcPlatform'})">信息化平台</span></dd>
                        <dd><span class="span" @click="$router.push({name: 'newDynamic'})">最新动态</span></dd>
                        <dd><span class="span" @click="$router.push({name: 'we'})">关于我们</span></dd>
                    </dl>
                </div>
                <div class="col-sm-2">
                    <dl>
                        <dt>智能应用</dt>
                        <dd v-for="(li,index) in foot" :key="index" @click="clickHerf(li.url,li.code)"><span class="yingyogn cursor">{{li.title}}</span></dd>
                    </dl>
                </div>
                <div class="col-sm-2">
                    <dl>
                        <dt>登录</dt>
                        <dd><a  :href="btnUrl[0].content">{{btnUrl[0].name}}</a></dd>
                        <dd><a  :href="btnUrl[1].content">{{btnUrl[1].name}}</a></dd>
                    </dl>
                </div>
                <div class="col-sm-4">
                    <dl>
                        <dt>联系我们</dt>
                        <dd> <span  class="phone">400-821-1618</span></dd>
                        <dd class="row"><span class="opacity6 col-sm-2" style="padding: 0 0 0 15px;margin: 0 0">地址：</span>
                            <span class="col-sm-10 opacity6" style="padding: 0 0;margin: 0 0">上海漕河泾开发区浦江高科技园陈行公路2388号浦江科技广场7幢801A室</span>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {listSecondLevelCatalogContent,listNavbarUrl} from '../serve/index.js'
    import {clickContent1} from '../lib/until.js'
    export default {
        name: "Foot",
        data() {
          return {
              foot: [],
              btnUrl: [{content: '',name: ''},{content: '',name: ''}]
          }
        },
        mounted() {
            this.listSecondLevelCatalogContent()
            this.listNavbarUrl()
        },
        methods: {
            clickHerf(hyperLink,code) {
                if (hyperLink) {
                    window.location.href = hyperLink
                    clickContent1(code)
                }
            },
            listNavbarUrl() {
                listNavbarUrl().then(res => {
                    if (res.resultCode == 200) {
                        this.btnUrl = res.data
                    }
                })
            },
            listSecondLevelCatalogContent() {
                listSecondLevelCatalogContent({topCatalogCode: 'zhinengyingyong'}).then(res => {
                    if (res.resultCode == 200) {
                            res.data.forEach(item => {
                            item.contents.forEach(a => {
                                this.foot.push({title: a.title,url: a.hyperLink,code: a.code})
                            })
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    dt {
        font-weight:400;
        color:rgba(255,255,255,255);
        line-height:40px;
    }
    dd {
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:40px;
    }
    .span {
        cursor: pointer;
        color:rgba(255,255,255,1);
        opacity: 0.6;
    }
    .span:hover {
        opacity: 1;
        text-decoration: none;
    }
    .yingyogn {
        color:rgba(255,255,255,1);
        opacity: 0.6;
    }
    .yingyogn:hover {
        opacity: 1;
    }
    a {
        color:rgba(255,255,255,1);
        opacity: 0.6;
    }
    a:hover {
        opacity: 1;
        text-decoration: none;
    }
    .opacity6 {
        opacity: 0.6;
    }
    .phone {
        color: #17A839;
        font-size: 22px;
    }
</style>