<!--
 * @Author: vince
 * @Date: 2023-06-26 14:17:12
 * @LastEditors: vince 273187189@qq.com
 * @LastEditTime: 2023-06-27 16:06:30
 * @Description: 
-->
<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/zbt_b.png" alt="" class="img-responsive" width="100%" @click="clickImg1">
            <div class="container bgS box boxM" style="margin-top: 50px">
                <p class="p1 font1" style="color: #D93F22;">支部通</p>
                <p class="p2 font2">
                    通过智能化、数字化、信息化的手段，为大学生党员队伍提供更便捷、更智能的服务，解决大学生线下入党流程繁琐的痛点。同时也为党建工作提供更科学、更高效的管理，实现运作高效、管理精细、服务便捷的现代化党员队伍建设，为大学生党员队伍建设管理赋能。
                </p>
            </div>
        </div>
        <div class="container-fluid xlwy" style="margin-top:-88px">
            <div class="container">
                <h2 style="text-align:center">支部通移动端</h2>
                <div style="margin-left: 12px;margin-bottom: 2rem">
                    <span class="hengxian zcolor"></span>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                        <div class="li-name" v-for="item, index in list" :key="index">
                            <div @click="clickLi(index)" :class="['li', index == current ? 'activeli' : '']">
                                <div class="li-title">
                                    {{ item.title }}</div>
                                <ul class="li-ul">
                                    <li :key="j" v-for="it, j in item.contents">{{ it }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                        <div class="swiper zbt-swiper">
                            <!-- Additional required wrapper -->
                            <div class="swiper-wrapper">
                                <!-- Slides -->
                                <div class="swiper-slide ">
                                    <img style="width:100%" :src="src1" alt="">
                                </div>
                                <div class="swiper-slide ">
                                    <img style="width:100%" :src="src2" alt="">
                                </div>
                                <div class="swiper-slide ">
                                    <img style="width:100%" :src="src3" alt="">
                                </div>
                                <div class="swiper-slide ">
                                    <img style="width:100%" :src="src4" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 style="text-align:center;margin-top:3rem">支部通管理后台</h2>
                <div style="margin-left: 12px;margin-bottom: 4rem">
                    <span class="hengxian zcolor"></span>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 ">
                        <img style="width:100%" src="../../assets/img/zbt_5.png" alt="">
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        <div v-for="item, index in list1" :key="index" class="li-name">
                            <div class="li-title">{{ item.title }} </div>
                            <ul class="li-ul">
                                <li :key="j" v-for="l, j in item.contents">{{ l }}</li>
                            </ul>
                        </div>
                    </div>

                </div>


            </div>
        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';
import Head from '../../components/header.vue'
import Foot from '../../components/footer.vue'
import FootBot from '../../components/footerBot.vue'
import { IsPC } from '../../lib/until.js'


import src1 from '../../assets/img/zbt_1.png'
import src2 from '../../assets/img/zbt_2.png'
import src3 from '../../assets/img/zbt_3.png'
import src4 from '../../assets/img/zbt_4.png'

export default {
    metaInfo: {
        title: '学多多官网—支部通',
        meta: [
            {
                name: 'keywords',
                content: ''
            }
        ]
    },
    name: "xlwy",
    components: {
        Head, Foot, FootBot
    },
    mounted() {
        this.ispc = IsPC()
        this.swiper = new Swiper('.swiper', {
            // Optional parameters
            // direction: 'vertical',
            loop: false,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            autoHeight: true,
            // If we need pagination
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true,
            // },

            // // Navigation arrows
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },

            // And if we need scrollbar
            // scrollbar: {
            //     el: '.swiper-scrollbar',
            // },
        });
        this.swiper.on('realIndexChange', (swiper) => {
            this.current = swiper.activeIndex;
        })
    },
    data() {
        return {
            swiper: null,
            current: 0,
            isActive1: 1,
            ispc: false,
            src1,
            src2,
            src3,
            src4,
            list: [
                { title: '首页', contents: ['浏览学校发布的各类党建资讯，常用功能快速导航'] },
                { title: '会议', contents: ['实时浏览全部会议议程，支持会议签到、投票选举、交流互动'] },
                { title: '活动', contents: ['掌握活动动态，一键报名活动过程管理与互动'] },
                { title: '我的', contents: ['平台使用情况一键查看，随时掌握个人入党流程与进度'] }],
            list1: [
                { title: '首页管理', contents: ['发布党建相关学习内容，以及通知公告'] },
                { title: '组织管理', contents: ['党组织管理和人员管理，旨在提升管理工具的效率和质量'] },
                { title: '平台管理', contents: ['平台的辅助板块，旨在有效管理平台的各项功能模块'] },
            ]
        }
    },
    methods: {
        clickImg1() {
            if (!this.ispc) {
                // window.location.href = ''
            }
        },
        clickLi(index) {
            this.swiper.slideTo(index)
        }

    }
}
</script>

<style lang="scss" scoped>
.zcolor {
    background-color: #CB3934;
    height: 6px;
    border-radius: 3px;
}

.zbt-swiper {
    background-color: #F5F5F5;
}

.li {
    border-radius: 12px;
    padding: 8px 30px 0px;
    margin-right: 50px;
    border: 3px solid transparent;
    cursor: pointer;

}

.activeli {
    box-shadow: 6px 6px 0px 0px rgba(218, 116, 112, 1);
    border: 3px solid #CB3934;

    .li-title {
        color: #CB3934;
    }

    .li-ul {
        color: #CB3934;
    }
}

.li-name {
    margin-bottom: 24px;

}

.li-title {
    margin: 0 0 14px;
    font-size: 24px;
    font-weight: 400;
    color: #212121;
}

.li-ul {
    list-style: none;
    font-size: 16px;
    font-weight: 500;
    color: #272727;
    line-height: 2;
    padding-left: 0px;

}
</style>