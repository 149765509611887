<template>
    <div>
        <Head></Head>
        <div class="container-fluid" style="padding: 0;">
            <img src="../../assets/img/szjz_1.png" alt="" class="img-responsive" width="100%" @click="clickImg1">
            <div class="container bgS box boxM" style="margin-top: 50px">
                <p class="p1 font1" style="color: #2BB8FF;">多多数字基座</p>
                <p class="p2 font2">
                    多多数字基座是全国首家校级数字基座，提供学生成长、教师发展、学校管理、课程建设四类应用，可供学校选择。
                    同时，多多数字基座通过统一用户中心实现用户信息统一管理，便于用户单点登录、统一认证。所有应用产生的数据可通过慧分析——大数据分析平台，实现数据快速查询、多维分析、数据分析挖掘，让学校管理层及时了解师生应用使用情况，洞察教与学存在的优势和不足。
                </p>
            </div>
        </div>
        <div class="hbbg" style="margin-top: -8rem">
            <div class="container-fluid ">
                <div class="container" style="margin-bottom: 30px">
                    <h2 class="hbbg2">
                        <img src="../../assets/img/szjz_4.png" style="height:43px" alt="">
                    </h2>
                    <img src="../../assets/img/szjz_2.png" alt="" class="img-responsive" style="margin:-58px auto 0">
                </div>
            </div>

            <div class="container-fluid ">
                <div class="container" style="margin-bottom: 30px">
                    <h2 class="hbbg2"><img style="height:43px" src="../../assets/img/szjz_5.png" alt=""></h2>
                    <img src="../../assets/img/szjz_3.png" alt="" class="img-responsive" style="margin:-58px auto 0">
                </div>
            </div>

        </div>

        <Foot></Foot>
        <FootBot></FootBot>
    </div>
</template>

<script>
import Head from '../../components/header.vue'
import Foot from '../../components/footer.vue'
import FootBot from '../../components/footerBot.vue'
import { IsPC } from '../../lib/until.js'



export default {
    metaInfo: {
        title: '学多多官网—多多数字基座',
        meta: [
            {
                name: 'keywords',
                content: ''
            }
        ]
    },
    name: "HuiBen",
    components: {
        Head, Foot, FootBot
    },
    mounted() {
        this.ispc = IsPC()
    },
    data() {
        return {
            isActive1: 1,
            src: src1,
            ispc: false
        }
    },
    methods: {
        clickImg1() {
            if (!this.ispc) {
               
            }
        },

    }
}
</script>

<style scoped>
.hbbg2 {
    text-align: center;

}

.hbbg3 {
    text-align: center;
    margin-top: 40px;
}

.hbbg4 {
    text-align: center;
    margin-top: 40px;
}

.hbbg5 {
    text-align: center;
    margin-top: 40px;
}
</style>