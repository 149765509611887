import {
    getData,
} from "../lib/fetch.js";
import config from "@/config.js";
let p = `/${config.projectName}/front/`

/*
* 首页 shouye
* 智能应用 zhinengyingyong
* 课程资源 kechengziyuan
* 信息化平台  xinxihuapingtai
* 最新动态   zuixindongtai
* 关于我们  guanyuwomen
* */


/**
 * @description 获取菜单
 */
export const listAllCatalogInfoInherit = () => {
    return getData(p + 'listAllCatalogInfoInherit', {})
}

/**
 * @description 点击内容
 * *code
 */
export const clickContent = (paramData) => {
    return getData(p + 'clickContent', paramData)
}

/**
 * @description 获取轮播图
 */
export const getCarousel = (paramData) => {
    return getData(p + 'getCarousel', paramData)
}

/**
 * @description 获取根目录下的子目录
 */
export const getSubCatalogContentDetail = (paramData) => {
    return getData(p + 'getSubCatalogContentDetail', paramData)
}

/**
 * @description 获取目录列表
 */
export const listCatalogInfo = (paramData) => {
    return getData(p + 'listCatalogInfo', paramData)
}

/**
 * @description 获取内容
 */
export const listContentInfo = (paramData) => {
    return getData(p + 'listContentInfo', paramData)
}

/**
 * @description 获取二级目录下的内容
 */
export const listSecondLevelCatalogContent = (paramData) => {
    return getData(p + 'listSecondLevelCatalogContent', paramData)
}

/**
 * @description 获取目录下所有上线的内容
 * *topCatalogCode
 */
export const listAllOnlineContentByTopParentCode = (paramData) => {
    return getData(p + 'listAllOnlineContentByTopParentCode', paramData)
}

/**
 * @description 获取富文本内容
 * *code
 */
export const getContentByContentCode = (paramData) => {
    return getData(p + 'getContentByContentCode', paramData)
}

/**
 * @description
 获取导航栏地址
 * *code
 */
export const listNavbarUrl = (paramData) => {
    return getData(p + 'listNavbarUrl', paramData)
}

/**
 * @description
 获取导航栏地址
 * *parentCatalogCode
 */
export const listContentInfoWithoutContent = (paramData) => {
    return getData(p + 'listContentInfoWithoutContent', paramData)
}